"use strict";

require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = exports.AddBallStepsEnum = exports.ADD_BALL_NUM_STEPS = void 0;
require("core-js/modules/es.promise.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _styles = require("@mui/styles");
var _material = require("@mui/material");
var _iconsMaterial = require("@mui/icons-material");
var _BallSource = _interopRequireDefault(require("../ball-form-steps/BallSource"));
var _BallType = require("../enums/BallType.enum");
var _BasicBallInfo = _interopRequireDefault(require("../ball-form-steps/BasicBallInfo"));
var _BallLocation = _interopRequireDefault(require("../ball-form-steps/BallLocation"));
var _BallDate = _interopRequireDefault(require("../ball-form-steps/BallDate"));
var _PreviewBall = _interopRequireDefault(require("../ball-form-steps/PreviewBall"));
var _BallDetail = _interopRequireDefault(require("../ball-form-steps/BallDetail"));
var _AddBallFlyer = _interopRequireDefault(require("../ball-form-steps/AddBallFlyer"));
var _NotifyOnApproval = _interopRequireDefault(require("../ball-form-steps/NotifyOnApproval"));
var _FinishAddBall = _interopRequireDefault(require("../ball-form-steps/FinishAddBall"));
var _AddBallCategories = _interopRequireDefault(require("../ball-form-steps/AddCategories/AddBallCategories"));
var _LoadingButton = _interopRequireDefault(require("../../../loading-button/LoadingButton"));
var _LegacyBallUtils = require("../LegacyBallUtils");
var _SanityContext = _interopRequireDefault(require("../../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const useStyles = exports.useStyles = (0, _styles.makeStyles)(theme => ({
  paper: {
    backgroundColor: 'whitesmoke !important',
    border: '2px solid #000'
  },
  modalProgressBar: {
    height: theme.spacing(1),
    width: '100%',
    backgroundColor: 'whitesmoke',
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.text.secondary
    }
  }
}));
var AddBallStepsEnum;
(function (AddBallStepsEnum) {
  AddBallStepsEnum[AddBallStepsEnum["SOURCE"] = 0] = "SOURCE";
  AddBallStepsEnum[AddBallStepsEnum["BASIC_INFO"] = 1] = "BASIC_INFO";
  AddBallStepsEnum[AddBallStepsEnum["BALL_DETAIL"] = 2] = "BALL_DETAIL";
  AddBallStepsEnum[AddBallStepsEnum["BALL_DATE"] = 3] = "BALL_DATE";
  AddBallStepsEnum[AddBallStepsEnum["BALL_FLYER"] = 4] = "BALL_FLYER";
  AddBallStepsEnum[AddBallStepsEnum["CATEGORIES"] = 5] = "CATEGORIES";
  AddBallStepsEnum[AddBallStepsEnum["BALL_LOCATION"] = 6] = "BALL_LOCATION";
  AddBallStepsEnum[AddBallStepsEnum["PREVIEW_BALL"] = 7] = "PREVIEW_BALL";
  AddBallStepsEnum[AddBallStepsEnum["NOTIFY_ON_APPROVAL"] = 8] = "NOTIFY_ON_APPROVAL";
  AddBallStepsEnum[AddBallStepsEnum["FINISH"] = 9] = "FINISH";
})(AddBallStepsEnum || (exports.AddBallStepsEnum = AddBallStepsEnum = {}));
const ADD_BALL_NUM_STEPS = exports.ADD_BALL_NUM_STEPS = 9;
const AddBallModal = props => {
  const classes = useStyles();
  const [stepCounter, setStepCounter] = (0, _react.useState)(0);
  const [newBallToAdd, setNewBallToAdd] = (0, _react.useState)({
    categories: []
  });
  const [isOpen, setIsOpen] = (0, _react.useState)(false);
  const [stepValidation, setStepValidation] = (0, _react.useState)({});
  const [submitStatus, setSubmitStatus] = (0, _react.useState)({
    value: null
  });
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  (0, _react.useEffect)(() => {
    if (props.ballToAdd) {
      setNewBallToAdd(props.ballToAdd);
    }
  }, [props.ballToAdd]);
  const updateAddBallForm = updatedFormParameters => {
    setNewBallToAdd(state => _objectSpread(_objectSpread({}, state), updatedFormParameters));
  };
  (0, _react.useEffect)(() => {
    setIsOpen(props.open);
  }, [props.open]);
  // const authenticatedUserContext = useContext(AuthenticatedUserContext)
  const validateStep = partialValidation => {
    setStepValidation(state => _objectSpread(_objectSpread({}, state), partialValidation));
  };
  const renderStep = () => {
    switch (stepCounter) {
      case AddBallStepsEnum.SOURCE:
        return (0, _jsxRuntime.jsx)(_BallSource.default, {
          stepComplete: updateAddBallForm,
          newBallToAdd: newBallToAdd
        });
      case AddBallStepsEnum.BASIC_INFO:
        return (0, _jsxRuntime.jsx)(_BasicBallInfo.default, {
          stepComplete: updateAddBallForm,
          newBallToAdd: newBallToAdd
        });
      case AddBallStepsEnum.BALL_LOCATION:
        return (0, _jsxRuntime.jsx)(_BallLocation.default, {
          stepComplete: updateAddBallForm,
          newBallToAdd: newBallToAdd
        });
      case AddBallStepsEnum.BALL_DETAIL:
        return (0, _jsxRuntime.jsx)(_BallDetail.default, {
          validateStep: validateStep,
          stepComplete: updateAddBallForm,
          newBallToAdd: newBallToAdd
        });
      case AddBallStepsEnum.BALL_FLYER:
        return (0, _jsxRuntime.jsx)(_AddBallFlyer.default, {
          stepComplete: updateAddBallForm,
          newBallToAdd: newBallToAdd
        });
      case AddBallStepsEnum.CATEGORIES:
        return (0, _jsxRuntime.jsx)(_AddBallCategories.default, {
          stepComplete: updateAddBallForm,
          newBallToAdd: newBallToAdd
        });
      case AddBallStepsEnum.BALL_DATE:
        return (0, _jsxRuntime.jsx)(_BallDate.default, {
          validateStep: validateStep,
          stepComplete: updateAddBallForm,
          newBallToAdd: newBallToAdd
        });
      case AddBallStepsEnum.NOTIFY_ON_APPROVAL:
        return (0, _jsxRuntime.jsx)(_NotifyOnApproval.default, {
          validateStep: validateStep,
          stepComplete: updateAddBallForm,
          newBallToAdd: newBallToAdd
        });
      case AddBallStepsEnum.PREVIEW_BALL:
        return (0, _jsxRuntime.jsx)(_PreviewBall.default, {
          newBallToAdd: newBallToAdd
        });
      case AddBallStepsEnum.FINISH:
        return (0, _jsxRuntime.jsx)(_FinishAddBall.default, {
          status: submitStatus,
          newBallToAdd: newBallToAdd
        });
      default:
        return (0, _jsxRuntime.jsx)(_BallSource.default, {
          stepComplete: updateAddBallForm,
          newBallToAdd: newBallToAdd
        });
    }
  };
  const renderBallType = ballType => {
    if (!ballType) return "";
    return (0, _BallType.renderBallTypeChoice)(ballType);
  };
  const theme = (0, _material.useTheme)();
  const renderCtaButtonText = () => {
    switch (stepCounter) {
      case AddBallStepsEnum.SOURCE:
        return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [(0, _jsxRuntime.jsx)(_material.Typography, {
            style: {
              display: 'inline',
              minWidth: '400px'
            },
            children: "What type of Ball do you want to add?"
          }), " ", (0, _jsxRuntime.jsx)(_iconsMaterial.ArrowRightAlt, {
            style: {
              marginLeft: theme.spacing(1)
            }
          })]
        });
      case AddBallStepsEnum.BASIC_INFO:
        return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [(0, _jsxRuntime.jsx)(_material.Typography, {
            style: {
              display: 'inline',
              minWidth: '400px'
            },
            children: newBallToAdd.ballType ? "Add Details About the ".concat(renderBallType(newBallToAdd.ballType)) : 'Choose the type of ball to continue...'
          }), " ", (0, _jsxRuntime.jsx)(_iconsMaterial.ArrowRightAlt, {
            style: {
              marginLeft: theme.spacing(1)
            }
          })]
        });
      case AddBallStepsEnum.BALL_LOCATION:
        return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [(0, _jsxRuntime.jsx)(_material.Typography, {
            style: {
              display: 'inline',
              minWidth: '400px'
            },
            children: "Preview your ".concat(renderBallType(newBallToAdd.ballType))
          }), (0, _jsxRuntime.jsx)(_iconsMaterial.ArrowRightAlt, {
            style: {
              marginLeft: theme.spacing(1)
            }
          })]
        });
      case AddBallStepsEnum.BALL_DETAIL:
        return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [(0, _jsxRuntime.jsx)(_material.Typography, {
            style: {
              display: 'inline',
              minWidth: '400px'
            },
            children: "When is the ball"
          }), (0, _jsxRuntime.jsx)(_iconsMaterial.ArrowRightAlt, {
            style: {
              marginLeft: theme.spacing(1)
            }
          })]
        });
      case AddBallStepsEnum.BALL_DATE:
        return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [(0, _jsxRuntime.jsx)(_material.Typography, {
            style: {
              display: 'inline',
              minWidth: '400px'
            },
            children: "Add a flyer for the Ball"
          }), (0, _jsxRuntime.jsx)(_iconsMaterial.ArrowRightAlt, {
            style: {
              marginLeft: theme.spacing(1)
            }
          })]
        });
      case AddBallStepsEnum.BALL_FLYER:
        return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [(0, _jsxRuntime.jsx)(_material.Typography, {
            style: {
              display: 'inline',
              minWidth: '400px'
            },
            children: "Add ball Categories"
          }), (0, _jsxRuntime.jsx)(_iconsMaterial.ArrowRightAlt, {
            style: {
              marginLeft: theme.spacing(1)
            }
          })]
        });
      case AddBallStepsEnum.CATEGORIES:
        return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [(0, _jsxRuntime.jsx)(_material.Typography, {
            style: {
              display: 'inline',
              minWidth: '400px'
            },
            children: "Add the Location of the ball"
          }), (0, _jsxRuntime.jsx)(_iconsMaterial.ArrowRightAlt, {
            style: {
              marginLeft: theme.spacing(1)
            }
          })]
        });
      case AddBallStepsEnum.NOTIFY_ON_APPROVAL:
        return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [(0, _jsxRuntime.jsxs)(_material.Typography, {
            style: {
              display: 'inline',
              minWidth: '400px'
            },
            children: ["".concat(!newBallToAdd.notifyOnApproval ? 'No, Submit the ' : 'Submit ', " ").concat(renderBallType(newBallToAdd.ballType)), "."]
          }), (0, _jsxRuntime.jsx)(_iconsMaterial.ArrowRightAlt, {
            style: {
              marginLeft: theme.spacing(1)
            }
          })]
        });
      case AddBallStepsEnum.PREVIEW_BALL:
        return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [(0, _jsxRuntime.jsxs)(_material.Typography, {
            style: {
              display: 'inline',
              minWidth: '400px'
            },
            children: ["Request updates on your ".concat(renderBallType(newBallToAdd.ballType)), "."]
          }), (0, _jsxRuntime.jsx)(_iconsMaterial.ArrowRightAlt, {
            style: {
              marginLeft: theme.spacing(1)
            }
          })]
        });
      case AddBallStepsEnum.FINISH:
        return 'Finished';
      default:
        return (0, _jsxRuntime.jsx)(_BasicBallInfo.default, {
          stepComplete: updateAddBallForm,
          newBallToAdd: newBallToAdd
        });
    }
  };
  const moveToNextStep = async () => {
    console.log('Moving to next step', stepCounter);
    if (stepCounter <= ADD_BALL_NUM_STEPS) {
      console.log('Moving to step:', AddBallStepsEnum[stepCounter + 1], stepCounter + 1);
      setStepCounter(stepCounter + 1);
    } else {
      console.log('Not sure how you got here but', AddBallStepsEnum[stepCounter], stepCounter);
      setStepCounter(AddBallStepsEnum.FINISH);
    }
    return Promise.resolve({
      value: AddBallStepsEnum[stepCounter],
      status: {
        statusCode: 200
      }
    });
  };
  const moveToPrevStep = () => {
    if (stepCounter > 0) {
      console.log('Moving to step:', AddBallStepsEnum[stepCounter - 1], stepCounter - 1);
      setStepCounter(stepCounter - 1);
    } else {
      console.log('Not sure how you got here but', AddBallStepsEnum[stepCounter], stepCounter);
      setStepCounter(AddBallStepsEnum.SOURCE);
    }
  };
  const convertToSlug = ballTitle => {
    if (!ballTitle) {
      return "";
    }
    return ballTitle.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
  };
  const addBall = ball => {
    const {
      fileUploaded
    } = ball;
    const flyerImgFile = fileUploaded;
    const slug = {
      _type: 'slug',
      current: convertToSlug(ball.ballTitle)
    };
    console.log("AddBall in Modal ball ", ball);
    console.log("contents of image uploaded ", flyerImgFile);
    // if (authenticatedUserContext.authentication?.awUserDetails?._id && authenticatedUserContext.authentication.awUserDetails._id !== '') {
    //   const userId = authenticatedUserContext.authentication?.awUserDetails?._id
    //
    //   const userRef: SanityRef = { _ref: userId, _type: 'reference' }
    //
    //   return awapiClient.addBall(fromStepFormToSanity({
    //     ...ball,
    //     slug,
    //     createdBy: userRef,
    //   }), flyerImgFile).then((ball) => {
    //     // set loading and submitted
    //     setSubmitStatus({ value:ball, status:{ statusCode: 200, messageText:'Add Ball Success!!!' } })
    //     setStepCounter(AddBallStepsEnum.FINISH)
    //       return ball
    //   }).catch((e) => {
    //     setSubmitStatus({ value:e, status:{ messageText: 'Error Adding Ball', statusCode: 400 } })
    //     setStepCounter(AddBallStepsEnum.FINISH)
    //     return ball
    //   })
    // }
    return sanityContext.addBall((0, _LegacyBallUtils.fromStepFormToSanity)(_objectSpread(_objectSpread({}, ball), {}, {
      slug
    })), flyerImgFile).then(() => {
      // set loading and submitted
      setSubmitStatus({
        value: ball,
        status: {
          statusCode: 200,
          messageText: 'Add Ball Success!!!'
        }
      });
      setStepCounter(AddBallStepsEnum.FINISH);
      return ball;
    }).catch(e => {
      console.log('ERRROR:', e);
      //@ts-ignore
      setSubmitStatus({
        value: e,
        status: {
          statusCode: 400,
          messageText: 'AWAPI: AddBall Failed'
        }
      });
      setStepCounter(AddBallStepsEnum.FINISH);
      return ball;
    });
  };
  return (0, _jsxRuntime.jsx)(_material.Modal, {
    open: isOpen,
    "data-testid": 'add-ball-modal',
    "aria-labelledby": 'add-ball-modal-title',
    "aria-describedby": 'add-ball-modal-desc',
    style: {
      height: 'max-content',
      maxWidth: '900px',
      minWidth: '300px',
      margin: 'auto'
    },
    children: (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      justifyContent: 'center',
      alignItems: 'center',
      className: classes.paper,
      children: (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        direction: 'column',
        item: true,
        spacing: 3,
        children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            style: {
              padding: theme.spacing(2.5),
              backgroundColor: theme.palette.text.secondary
            },
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              xs: 2
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              xs: 8,
              children: (0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                item: true,
                justifyContent: 'center',
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  style: {
                    color: 'whitesmoke'
                  },
                  variant: 'h4',
                  "aria-label": 'add-ball-modal-title',
                  "data-testid": 'add-ball-modal-title',
                  align: 'center',
                  children: "Add A New Ball"
                })
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              xs: 2,
              justifyContent: 'flex-end',
              children: (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Button, {
                  children: (0, _jsxRuntime.jsx)(_iconsMaterial.Close, {
                    onClick: () => setIsOpen(false),
                    fontSize: 'large',
                    style: {
                      color: '#FAFAFA'
                    }
                  })
                })
              })
            })]
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            children: (0, _jsxRuntime.jsx)(_material.LinearProgress, {
              variant: 'determinate',
              value: (stepCounter.valueOf() + 1) / (ADD_BALL_NUM_STEPS + 1) * 100,
              className: classes.modalProgressBar,
              "data-testid": 'add-ball-progress-bar'
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          direction: 'column',
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            style: {
              overflowY: 'scroll',
              height: '600px',
              padding: theme.spacing(0, 2)
            },
            children: renderStep()
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            justifyContent: 'center',
            children: stepCounter !== AddBallStepsEnum.FINISH && (0, _jsxRuntime.jsx)(_LoadingButton.default, {
              disabled: stepCounter === AddBallStepsEnum.SOURCE && !newBallToAdd.source || stepCounter === AddBallStepsEnum.BASIC_INFO && !newBallToAdd.ballType || stepCounter === AddBallStepsEnum.BALL_DETAIL && !stepValidation.isBallDetailValid || stepCounter === AddBallStepsEnum.BALL_LOCATION && !newBallToAdd.location || stepCounter === AddBallStepsEnum.BALL_FLYER && !newBallToAdd.flyer || stepCounter === AddBallStepsEnum.BALL_DATE && !stepValidation.areDatesValid || stepCounter === AddBallStepsEnum.NOTIFY_ON_APPROVAL && newBallToAdd.notifyOnApproval && (!newBallToAdd.notifyEmail || !newBallToAdd.notifyName),
              clickHandler: stepCounter === ADD_BALL_NUM_STEPS - 1 ? () => {
                return addBall(newBallToAdd);
              } : () => {
                return moveToNextStep();
              },
              width: 500,
              children: renderCtaButtonText()
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            style: {
              height: '64px'
            },
            children: stepCounter !== AddBallStepsEnum.FINISH && stepCounter > 0 && (0, _jsxRuntime.jsxs)(_material.Button, {
              "data-testid": 'add-ball-previous-step-button',
              onClick: moveToPrevStep,
              children: [(0, _jsxRuntime.jsx)(_iconsMaterial.ArrowLeft, {}), "Previous Question"]
            })
          })]
        })]
      })
    })
  });
};
var _default = exports.default = AddBallModal;