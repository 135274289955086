"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _styles = require("@mui/styles");
var _BallSource = _interopRequireDefault(require("../enums/BallSource.enum"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _styles.makeStyles)(() => ({
  ballSourceButton: {
    height: '100px',
    minWidth: '100px'
  }
}));
const BallSource = props => {
  var _props$newBallToAdd2;
  const classes = useStyles();
  const [activeBallSource, setActiveBallSource] = (0, _react.useState)();
  function renderBallSourceChoice(ballSourceChoice) {
    if (!ballSourceChoice) return '';
    switch (ballSourceChoice) {
      case _BallSource.default.SPECTATOR:
        return 'Spectator';
      case _BallSource.default.PROMOTER:
        return 'Promoter';
      default:
        return '';
    }
  }
  function renderBallSource() {
    return renderBallSourceChoice(activeBallSource);
  }
  (0, _react.useEffect)(() => {
    if (activeBallSource != null) {
      props.stepComplete({
        source: activeBallSource
      });
    }
  }, [activeBallSource]);
  (0, _react.useEffect)(() => {
    var _props$newBallToAdd;
    if ((_props$newBallToAdd = props.newBallToAdd) !== null && _props$newBallToAdd !== void 0 && _props$newBallToAdd.source) {
      setActiveBallSource(props.newBallToAdd.source);
    }
  }, [(_props$newBallToAdd2 = props.newBallToAdd) === null || _props$newBallToAdd2 === void 0 ? void 0 : _props$newBallToAdd2.source]);
  const theme = (0, _material.useTheme)();
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    "data-testid": 'add-ball-source-step',
    direction: 'column',
    spacing: 2,
    children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'h5',
          color: 'textSecondary',
          children: "Tell us who you are..."
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          style: {
            paddingLeft: theme.spacing(2)
          },
          variant: 'h5',
          "data-testid": 'ball-source-choice',
          align: 'center',
          color: 'primary',
          children: renderBallSource()
        })
      })]
    }), (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      spacing: 1,
      style: {
        paddingTop: theme.spacing(2.25),
        height: "450px"
      },
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'h6',
          style: {
            textTransform: 'uppercase'
          },
          color: 'textSecondary',
          children: "Choose below:"
        })
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        alignItems: 'center',
        justifyContent: 'space-around',
        direction: "column",
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          xs: 6,
          children: (0, _jsxRuntime.jsx)(_material.Button, {
            color: "primary",
            className: classes.ballSourceButton,
            fullWidth: true,
            "data-testid": 'ball-source-spectator-button',
            variant: "".concat(activeBallSource === _BallSource.default.SPECTATOR ? 'contained' : 'outlined'),
            onClick: () => setActiveBallSource(_BallSource.default.SPECTATOR),
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: 'h6',
              children: "Ball Spectator"
            })
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          xs: 6,
          children: (0, _jsxRuntime.jsx)(_material.Button, {
            color: "primary",
            className: classes.ballSourceButton,
            fullWidth: true,
            "data-testid": 'ball-source-organizer-button',
            variant: "".concat(activeBallSource === _BallSource.default.PROMOTER ? 'contained' : 'outlined'),
            onClick: () => setActiveBallSource(_BallSource.default.PROMOTER),
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: 'h6',
              children: "Ball Organizer"
            })
          })
        })]
      })]
    })]
  });
};
var _default = exports.default = BallSource;