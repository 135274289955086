"use strict";

require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _StepFormValidationCodes = _interopRequireDefault(require("../enums/StepFormValidationCodes.enum"));
var _HTMLUtils = require("../HTMLUtils");
var _AWTextField = _interopRequireDefault(require("../aw-text-field/AWTextField"));
var _BallType = require("../enums/BallType.enum");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const BallDetail = props => {
  const [ballDetailState, setBallDetailState] = (0, _react.useState)({});
  (0, _react.useEffect)(() => {
    var _validateBallDetail$s, _validateBallDetail$s2, _validateBallDetail$s3, _validateWebsiteUrl$s;
    const isDetailValid = ballDetailState.ballTitle && ballDetailState.ballTitle.length > 0 && ballDetailState.description && ballDetailState.description.length > 0 && ballDetailState.host && ballDetailState.host.length > 0 && ballDetailState.website && ballDetailState.website.length > 0 && ((_validateBallDetail$s = validateBallDetail(ballDetailState.ballTitle).status) === null || _validateBallDetail$s === void 0 ? void 0 : _validateBallDetail$s.statusCode) === _StepFormValidationCodes.default.VALID && ((_validateBallDetail$s2 = validateBallDetail(ballDetailState.description).status) === null || _validateBallDetail$s2 === void 0 ? void 0 : _validateBallDetail$s2.statusCode) === _StepFormValidationCodes.default.VALID && ((_validateBallDetail$s3 = validateBallDetail(ballDetailState.host).status) === null || _validateBallDetail$s3 === void 0 ? void 0 : _validateBallDetail$s3.statusCode) === _StepFormValidationCodes.default.VALID && ((_validateWebsiteUrl$s = validateWebsiteUrl(ballDetailState.website).status) === null || _validateWebsiteUrl$s === void 0 ? void 0 : _validateWebsiteUrl$s.statusCode) === _StepFormValidationCodes.default.VALID;
    if (isDetailValid !== undefined) {
      let newValue;
      if (isDetailValid === "") newValue = false;else newValue = isDetailValid;
      props.validateStep({
        isBallDetailValid: newValue
      });
    }
    if (isDetailValid) {
      props.stepComplete(ballDetailState);
    }
  }, [ballDetailState.ballTitle, ballDetailState.description, ballDetailState.host, ballDetailState.website]);
  (0, _react.useEffect)(() => {
    setBallDetailState(state => _objectSpread(_objectSpread({}, state), {}, {
      description: props.newBallToAdd.description
    }));
  }, [props.newBallToAdd.description]);
  (0, _react.useEffect)(() => {
    setBallDetailState(state => _objectSpread(_objectSpread({}, state), {}, {
      host: props.newBallToAdd.host
    }));
  }, [props.newBallToAdd.host]);
  (0, _react.useEffect)(() => {
    setBallDetailState(state => _objectSpread(_objectSpread({}, state), {}, {
      website: props.newBallToAdd.website
    }));
  }, [props.newBallToAdd.website]);
  (0, _react.useEffect)(() => {
    setBallDetailState(state => _objectSpread(_objectSpread({}, state), {}, {
      ballTitle: props.newBallToAdd.ballTitle
    }));
  }, [props.newBallToAdd.ballTitle]);
  const updateBallFormParams = event => {
    setBallDetailState(state => _objectSpread(_objectSpread({}, state), {}, {
      [event.target.name]: event.target.value
    }));
  };
  const validateBallDetail = ballDetail => {
    let response = {
      value: ballDetail
    };
    if (ballDetail && ballDetail.length <= 1) {
      response = _objectSpread(_objectSpread({}, response), {}, {
        status: {
          messageText: 'Must be longer than 1 character.',
          statusCode: _StepFormValidationCodes.default.BALL_TITLE_TOO_SHORT
        }
      });
    } else {
      response = _objectSpread(_objectSpread({}, response), {}, {
        status: {
          statusCode: _StepFormValidationCodes.default.VALID
        }
      });
    }
    return response;
  };
  const validateBallHost = ballHost => {
    let response = {
      value: ballHost
    };
    if (ballHost && ballHost.length <= 1) {
      response = _objectSpread(_objectSpread({}, response), {}, {
        status: {
          messageText: 'Hosts\' names must be longer than 1 character.',
          statusCode: _StepFormValidationCodes.default.BALL_HOST_TOO_SHORT
        }
      });
    } else {
      response = _objectSpread(_objectSpread({}, response), {}, {
        status: {
          statusCode: _StepFormValidationCodes.default.VALID
        }
      });
    }
    return response;
  };
  const validateWebsiteUrl = website => {
    let response = {
      value: website
    };
    if (!website || website === '') {
      response = _objectSpread(_objectSpread({}, response), {}, {
        status: {
          statusCode: _StepFormValidationCodes.default.VALID
        }
      });
      return response;
    }
    const isValidURL = (0, _HTMLUtils.urlPatternValidation)(website);
    if (!isValidURL) {
      response = _objectSpread(_objectSpread({}, response), {}, {
        status: {
          messageText: 'The URL is formatted incorrectly. Try something like http://www.website.com',
          statusCode: _StepFormValidationCodes.default.URL_INVALID
        }
      });
      return response;
    }
    response = _objectSpread(_objectSpread({}, response), {}, {
      status: {
        statusCode: _StepFormValidationCodes.default.VALID
      }
    });
    return response;
  };
  const validateDescription = description => {
    let response = {
      value: description
    };
    if (description && description.length <= 1) {
      response = _objectSpread(_objectSpread({}, response), {}, {
        status: {
          messageText: 'Description must be longer than 1 character.',
          statusCode: _StepFormValidationCodes.default.BALL_DESCRIPTION_TOO_SHORT
        }
      });
    } else {
      response = _objectSpread(_objectSpread({}, response), {}, {
        status: {
          statusCode: _StepFormValidationCodes.default.VALID
        }
      });
    }
    return response;
  };
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    "data-testid": 'add-ball-detail-step',
    direction: 'column',
    spacing: 2,
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      children: (0, _jsxRuntime.jsxs)(_material.Typography, {
        variant: 'h5',
        color: 'textSecondary',
        children: ["Tell me more about the ", (0, _BallType.renderBallType)(props.newBallToAdd.ballType), "..."]
      })
    }), (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      direction: 'column',
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: (0, _jsxRuntime.jsx)(_AWTextField.default, {
          onChange: updateBallFormParams,
          value: ballDetailState.ballTitle,
          testId: 'ball-title',
          fieldLabel: "".concat((0, _BallType.renderBallType)(props.newBallToAdd.ballType), " Title"),
          fieldName: 'ballTitle',
          validate: validateBallDetail
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: (0, _jsxRuntime.jsx)(_AWTextField.default, {
          onChange: updateBallFormParams,
          value: ballDetailState.host,
          testId: 'ball-host',
          fieldLabel: (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
            children: [(0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
              children: "".concat((0, _BallType.renderBallType)(props.newBallToAdd.ballType), " Organizer")
            }), (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: 'h6',
              display: 'inline',
              style: {
                textTransform: 'lowercase'
              },
              children: "(s)"
            })]
          }),
          fieldName: 'host',
          validate: validateBallHost
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: (0, _jsxRuntime.jsx)(_AWTextField.default, {
          onChange: updateBallFormParams,
          value: ballDetailState.website,
          testId: 'ball-website',
          fieldLabel: 'Website URL:',
          fieldName: 'website',
          validate: validateWebsiteUrl
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: (0, _jsxRuntime.jsx)(_AWTextField.default, {
          onChange: updateBallFormParams,
          value: ballDetailState.description,
          testId: 'ball-description',
          fieldLabel: 'Description',
          fieldName: 'description',
          multiline: true,
          validate: validateDescription
        })
      })]
    })]
  });
};
var _default = exports.default = BallDetail;