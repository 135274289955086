"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _ballroomTypes = require("../../ballroomTypes");
var _material = require("@mui/material");
var _iconsMaterial = require("@mui/icons-material");
const ClosedCategory = _ref => {
  let {
    keyValue,
    category,
    editCategory,
    deleteCategory,
    showDescription,
    showMenu
  } = _ref;
  const theme = (0, _material.useTheme)();
  const smDown = (0, _material.useMediaQuery)(theme.breakpoints.down('sm'));
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    spacing: 1,
    alignItems: 'stretch',
    style: {
      backgroundColor: '#FAFAFA',
      border: "2px solid ".concat(theme.palette.text.secondary),
      marginBottom: theme.spacing(1)
    },
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      xs: 3,
      alignItems: 'center',
      justifyContent: 'center',
      children: (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        direction: 'column',
        alignItems: 'center',
        spacing: 1,
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            "data-testid": "category-".concat(keyValue, "-closed-catGender"),
            align: 'center',
            color: 'textSecondary',
            variant: category.versus ? 'h6' : 'h5',
            noWrap: true,
            style: {
              textTransform: 'capitalize'
            },
            display: 'inline',
            children:
            //@ts-ignore
            _ballroomTypes.CategoryGenderType[category.catGender]
          })
        }), category.versus && (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            align: 'center',
            "data-testid": "category-".concat(keyValue, "-closed-versus"),
            color: 'textSecondary',
            variant: 'subtitle2',
            noWrap: true,
            style: {
              textTransform: 'capitalize'
            },
            display: 'inline',
            children: "versus"
          })
        }), category.versus && (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            align: 'center',
            "data-testid": "category-".concat(keyValue, "-closed-catVsGender"),
            color: 'textSecondary',
            variant: 'h6',
            noWrap: true,
            style: {
              textTransform: 'capitalize'
            },
            display: 'inline',
            children:
            //@ts-ignore
            _ballroomTypes.CategoryGenderType[category.catVsGender]
          })
        })]
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      xs: smDown || !showMenu ? 9 : 7,
      style: {
        paddingRight: theme.spacing(3)
      },
      children: (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        direction: 'column',
        alignContent: 'space-between',
        justifyContent: 'space-between',
        children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'space-between',
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            xs: 6,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              "data-testid": "category-".concat(keyValue, "-closed-catType"),
              color: 'textSecondary',
              variant: 'h6',
              noWrap: true,
              style: {
                textTransform: 'capitalize'
              },
              display: 'inline',
              children:
              //@ts-ignore
              _ballroomTypes.CategoryTypeType[category.catType]
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            xs: 6,
            justifyContent: 'flex-end',
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              color: 'textSecondary',
              variant: 'h6',
              noWrap: true,
              "data-testid": "category-".concat(keyValue, "-closed-catName"),
              style: {
                textTransform: 'capitalize'
              },
              display: 'inline',
              children:
              //@ts-ignore
              _ballroomTypes.CategoryNameType[category.catName]
            })
          })]
        }), showDescription && (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          "data-testid": "category-".concat(keyValue, "-description"),
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            xs: 12,
            style: {
              paddingBottom: theme.spacing(1),
              paddingTop: theme.spacing(1)
            },
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              "data-testid": "category-".concat(keyValue, "-closed-catDescription"),
              color: 'textSecondary',
              variant: 'body1',
              style: {
                textTransform: 'capitalize'
              },
              children: category.catDescription
            })
          })
        }), (0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'space-between',
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            xs: 6,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              color: 'textSecondary',
              variant: 'h6',
              noWrap: true,
              style: {
                textTransform: 'capitalize'
              },
              "data-testid": "category-".concat(keyValue, "-closed-catPrize"),
              display: 'inline',
              children: "Prize: ".concat(
              //@ts-ignore
              _ballroomTypes.CategoryPrizeType[category.catPrize])
            })
          }), category.monetaryPrize > 0 && (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            xs: 6,
            justifyContent: 'flex-end',
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              color: 'textSecondary',
              variant: 'h6',
              noWrap: true,
              "data-testid": "category-".concat(keyValue, "-closed-monetaryPrize"),
              style: {
                textTransform: 'capitalize'
              },
              display: 'inline',
              children: "$ ".concat(category.monetaryPrize)
            })
          })]
        })]
      })
    }), showMenu && (0, _jsxRuntime.jsxs)(_material.Grid, {
      "data-testid": 'category-1-menu',
      container: true,
      item: true,
      xs: smDown ? 12 : 2,
      justifyContent: 'space-around',
      alignItems: 'center',
      wrap: 'nowrap',
      style: smDown ? {
        borderTop: "2px solid ".concat(theme.palette.text.secondary),
        backgroundColor: '#c7c7c7'
      } : {
        borderLeft: "2px solid ".concat(theme.palette.text.secondary),
        backgroundColor: '#c7c7c7'
      },
      children: [(0, _jsxRuntime.jsx)(_material.Button, {
        children: (0, _jsxRuntime.jsx)(_iconsMaterial.Edit, {
          "data-testid": "category-".concat(keyValue, "-closed-editButton"),
          fontSize: 'medium',
          onClick: () => editCategory(category, keyValue)
        })
      }), (0, _jsxRuntime.jsx)(_material.Button, {
        children: (0, _jsxRuntime.jsx)(_iconsMaterial.Delete, {
          "data-testid": "category-".concat(keyValue, "-closed-deleteButton"),
          fontSize: 'medium',
          onClick: () => deleteCategory(category, keyValue)
        })
      })]
    })]
  });
};
var _default = exports.default = ClosedCategory;