"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _BallSearchProvider = _interopRequireDefault(require("./ball-search-context/BallSearchProvider"));
var _material = require("@mui/material");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const BallSearchProviderWrapper = props => {
  return props.results ? (0, _jsxRuntime.jsxs)(_BallSearchProvider.default, {
    balls: props.results,
    children: [(0, _jsxRuntime.jsx)(_material.Typography, {
      variant: 'h1',
      color: 'textSecondary',
      children: "WRAPPED"
    }), props.children]
  }) : (0, _jsxRuntime.jsxs)(_BallSearchProvider.default, {
    children: [(0, _jsxRuntime.jsx)(_material.Typography, {
      variant: 'h1',
      color: 'textSecondary',
      children: "UNWRAPPED"
    }), props.children]
  });
};
var _default = exports.default = BallSearchProviderWrapper;