"use strict";

require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.promise.js");
require("core-js/modules/es.promise.finally.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _BallSearchContext = _interopRequireDefault(require("./BallSearchContext"));
var _reactRouterDom = require("react-router-dom");
var _SanityContext = _interopRequireDefault(require("../../../../common/sanityIo/sanity-context/SanityContext"));
var _Routes = require("../enums/Routes.enum");
var _urlUtils = require("../urlUtils");
var _PageContext = _interopRequireDefault(require("../../../page-context/PageContext"));
var _Region = require("../enums/Region.enum");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const BallSearchProvider = props => {
  const navigate = (0, _reactRouterDom.useNavigate)();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const [viewType, setViewType] = (0, _react.useState)(true);
  const [searchParams, setSearchParams] = (0, _react.useState)({});
  const [displayResults, setDisplayResults] = (0, _react.useState)(undefined);
  const [loading, setLoading] = (0, _react.useState)(false);
  const initialState = {
    keywords: '',
    ballType: '',
    endDate: '',
    startDate: '',
    region: ''
  };
  const getBallData = async queryStringObj => {
    const queryString = generateBallQueryString(queryStringObj);
    console.log("The Sanity Context in the Ball Search Provider", sanityContext);
    return sanityContext.fetchAllApprovedBalls(queryString).then(sanitySearchResults => {
      console.log('the ball dATA IS', sanitySearchResults);
      setDisplayResults(sanitySearchResults);
      return sanitySearchResults;
    });
  };
  const refreshSearchResults = queryObject => {
    setLoading(true);
    // setSearchParams(queryObject)
    return getBallData(queryObject).finally(() => {
      setLoading(false);
    });
  };
  const resetSearchResults = async () => {
    setSearchParams(initialState);
    await refreshSearchResults(initialState);
  };
  // useEffect(() => {
  //     if(!props.balls) {
  //         resetSearchResults().then()
  //     }
  //         else
  //         setDisplayResults(props.balls)
  //     }, [ props.balls])
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const getBall = slug => {
    let urlParams = '';
    if (!isSearchParamsEmpty()) {
      urlParams = "?".concat((0, _urlUtils.getUrlSearchParamsStr)(searchParams));
    }
    // if(pageContext.baseRoute)
    console.log("aw - Navigating to ", "/".concat(pageContext.baseRoute, "/ball").concat(_Routes.RoutesEnum.BALL, "/").concat(slug).concat(urlParams));
    navigate("/".concat(pageContext.baseRoute, "/ball").concat(_Routes.RoutesEnum.BALL, "/").concat(slug).concat(urlParams));
    navigate(0);
  };
  const updateSearchParams = event => {
    setSearchParams(currentState => _objectSpread(_objectSpread({}, currentState), {}, {
      [event.target.name]: event.target.value
    }));
  };
  const isSearchParamsEmpty = () => (!(searchParams !== null && searchParams !== void 0 && searchParams.region) || (searchParams === null || searchParams === void 0 ? void 0 : searchParams.region) === '') && (!(searchParams !== null && searchParams !== void 0 && searchParams.keywords) || (searchParams === null || searchParams === void 0 ? void 0 : searchParams.keywords) === '') && (!(searchParams !== null && searchParams !== void 0 && searchParams.endDate) || (searchParams === null || searchParams === void 0 ? void 0 : searchParams.endDate) === '') && (!(searchParams !== null && searchParams !== void 0 && searchParams.startDate) || (searchParams === null || searchParams === void 0 ? void 0 : searchParams.startDate) === '') && (!(searchParams !== null && searchParams !== void 0 && searchParams.ballType) || (searchParams === null || searchParams === void 0 ? void 0 : searchParams.ballType) === '');
  const generateBallQueryString = queryStringObj => {
    let queryString = '';
    if (queryStringObj !== null && queryStringObj !== void 0 && queryStringObj.keywords && (queryStringObj === null || queryStringObj === void 0 ? void 0 : queryStringObj.keywords) !== '') {
      const normalizedKeywords = queryStringObj.keywords.toLowerCase();
      queryString += "[description, ballTitle, ballType,location.locationName, location.city, location.state] match \"".concat(normalizedKeywords, "\"");
      // queryString += ` || location.city matches "${normalizedKeywords}"`
      // queryString += `  || location.state matches "${normalizedKeywords}")`
    }
    if (queryStringObj !== null && queryStringObj !== void 0 && queryStringObj.ballType && (queryStringObj === null || queryStringObj === void 0 ? void 0 : queryStringObj.ballType) !== '') {
      queryString += queryString && queryString.length > 0 ? ' && ' : '';
      queryString += "ballType match \"".concat(queryStringObj.ballType, "\"");
    }
    if (queryStringObj !== null && queryStringObj !== void 0 && queryStringObj.region && (queryStringObj === null || queryStringObj === void 0 ? void 0 : queryStringObj.region) !== '') {
      queryString += queryString && queryString.length > 0 ? ' && ' : '';
      let translatedRegion = queryStringObj.region;
      switch (queryStringObj.region) {
        case _Region.RegionEnum.ABROAD:
          translatedRegion = _Region.RegionTitleEnum.ABROAD;
          break;
        case _Region.RegionEnum.CANADA:
          translatedRegion = _Region.RegionTitleEnum.CANADA;
          break;
        case _Region.RegionEnum.EAST_COAST:
          translatedRegion = _Region.RegionTitleEnum.EAST_COAST;
          break;
        case _Region.RegionEnum.THE_MIDWEST:
          translatedRegion = _Region.RegionTitleEnum.THE_MIDWEST;
          break;
        case _Region.RegionEnum.THE_SOUTH:
          translatedRegion = _Region.RegionTitleEnum.THE_SOUTH;
          break;
        case _Region.RegionEnum.WEST_COAST:
          translatedRegion = _Region.RegionTitleEnum.WEST_COAST;
          break;
        default:
      }
      queryString += "(region match \"".concat(translatedRegion, "\" || ");
      queryString += "region match \"".concat(queryStringObj.region, "\")");
    }
    if (queryStringObj !== null && queryStringObj !== void 0 && queryStringObj.startDate && (queryStringObj === null || queryStringObj === void 0 ? void 0 : queryStringObj.startDate) !== '') {
      queryString += queryString && queryString.length > 0 ? ' && ' : '';
      queryString += "functionStartDate > \"".concat(queryStringObj.startDate, "\"");
    }
    if (queryStringObj !== null && queryStringObj !== void 0 && queryStringObj.endDate && (queryStringObj === null || queryStringObj === void 0 ? void 0 : queryStringObj.endDate) !== '') {
      queryString += queryString && queryString.length > 0 ? ' && ' : '';
      queryString += "functionStartDate < \"".concat(queryStringObj.endDate, "\"");
    }
    if (queryString.length > 0) {
      queryString = " && (".concat(queryString, ")");
    }
    return queryString;
  };
  (0, _react.useEffect)(() => {
    console.log('updated Search params', searchParams);
    setLoading(true);
    // redo query
    console.log('queryObject', searchParams);
    refreshSearchResults(searchParams !== null && searchParams !== void 0 ? searchParams : {}).then();
  }, [searchParams]);
  (0, _react.useEffect)(() => {
    refreshSearchResults(searchParams !== null && searchParams !== void 0 ? searchParams : {}).then();
  }, []);
  return (0, _jsxRuntime.jsx)(_BallSearchContext.default.Provider, {
    value: _objectSpread({
      searchParams,
      displayResults,
      setSearchParams,
      setLoading,
      setDisplayResults,
      generateBallQueryString,
      updateSearchParams,
      refreshSearchResults,
      resetSearchResults,
      isSearchParamsEmpty,
      viewType,
      setViewType,
      // updateViewType,
      loading,
      getBall
    }, props.value),
    children: props.children
  });
};
var _default = exports.default = BallSearchProvider;