"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.promise.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/es.string.search.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _iconsMaterial = require("@mui/icons-material");
var _reactRouterDom = require("react-router-dom");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _styles = require("@mui/material/styles");
var _Routes = require("../enums/Routes.enum");
var _SanityContext = _interopRequireDefault(require("../../../../common/sanityIo/sanity-context/SanityContext"));
var _HTMLUtils = require("../HTMLUtils");
var _BallMapComponent = _interopRequireDefault(require("../ball-form-steps/BallMapComponent"));
var _ClosedCategory = _interopRequireDefault(require("../ball-form-steps/AddCategories/ClosedCategory"));
var _FirebaseContext = _interopRequireDefault(require("../../../../common/firebase/firebase-context/FirebaseContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(awTheme => ({
  heroImage: {
    // left: '-16px',
    // width: '100%',
    minWidth: '540px',
    // maxWidth: '720px',
    minHeight: '360px'
  },
  // ballSummaryOrganizer: {
  //   color: '#6F7287'
  // },
  favoritesShareContainer: {
    borderStyle: 'solid',
    borderColor: '#DBDAE3',
    borderWidth: '1px 0',
    backgroundColor: 'whitesmoke'
  },
  ballDescription: {
    whiteSpace: 'pre-wrap'
  },
  leftContent: {
    paddingTop: "16px",
    paddingLeft: "8px",
    [awTheme.breakpoints.up('md')]: {
      paddingLeft: "".concat(awTheme.spacing(12), "px !important")
    }
  },
  littleFaves: {
    [awTheme.breakpoints.up('sm')]: {
      marginRight: '10%'
    },
    [awTheme.breakpoints.down('xs')]: {
      marginRight: '45%'
    },
    height: '78px',
    position: 'relative',
    top: 38,
    borderRadius: '36px',
    backgroundColor: 'whitesmoke'
  },
  dateText: {
    marginLeft: awTheme.spacing(2)
  }
}));
const BallPageBaseNew = props => {
  var _ball$flyer, _ball$flyer2, _ball$functionStartDa, _ball$website, _ball$host, _ball$location, _ball$location2, _ball$location3, _ball$location4, _ball$location5, _ball$description, _ball$categories, _ball$location6, _ball$location7, _ball$location8, _ball$location9, _ball$location10, _ball$location11, _ball$location12, _ball$location13, _ball$location14;
  const classes = useStyles();
  const location = (0, _reactRouterDom.useLocation)();
  const urlParams = (0, _reactRouterDom.useParams)();
  const navigate = (0, _reactRouterDom.useNavigate)();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const firebaseContext = (0, _react.useContext)(_FirebaseContext.default);
  const [ball, setBall] = (0, _react.useState)();
  const getBallBySlug = slug => {
    return slug ? sanityContext.theSanityClient.getBallBySlug(slug).then(returnedBall => {
      setBall(returnedBall);
      return returnedBall;
    }) : Promise.resolve({});
  };
  (0, _react.useEffect)(() => {
    if (ball && firebaseContext.analyticsViewBall) {
      firebaseContext.analyticsViewBall(ball);
    }
  }, [ball]);
  (0, _react.useEffect)(() => {
    if (!props.slug) {
      getBallBySlug(urlParams.slug).then(retrievedBall => {
        console.log('retrieved from sanity', retrievedBall);
        // @ts-ignore
        if (retrievedBall && retrievedBall !== {}) {
          // firebaseAnalyticsClient.analyticsViewBall(retrievedBall)
        } else {
          const {
            ballToPreview
          } = location.state;
          if (ballToPreview) {
            // translate to a Sanity Ball
            const sanityBall = {
              ballTitle: ballToPreview.ballTitle,
              ballType: ballToPreview.ballType.toString(),
              flyer: ballToPreview.flyer,
              // flyer?: SanityImage,
              host: ballToPreview.host,
              categories: ballToPreview.categories,
              description: ballToPreview.ballDescription,
              functionStartDate: ballToPreview.functionStartDate,
              functionEndDate: ballToPreview.functionEndDate,
              source: ballToPreview.source.toString(),
              location: {
                locationName: ballToPreview.location.locationName,
                url: ballToPreview.location.url,
                country: ballToPreview.location.country,
                city: ballToPreview.location.city,
                street1: ballToPreview.location.street1,
                street2: ballToPreview.location.street2,
                state: ballToPreview.location.state
              },
              website: ballToPreview.website,
              notifyEmail: ballToPreview.notifyEmail,
              notifyName: ballToPreview.notifyName,
              notifyOnApproval: ballToPreview.notifyOnApproval
            };
            setBall(sanityBall);
          }
        }
      });
    }
  }, [props.slug]);
  (0, _react.useEffect)(() => {
    const ballToPreview = props.ball;
    if (ballToPreview) {
      var _ballToPreview$ballTy, _ballToPreview$source, _ballToPreview$locati, _ballToPreview$locati2, _ballToPreview$locati3, _ballToPreview$locati4, _ballToPreview$locati5, _ballToPreview$locati6, _ballToPreview$locati7;
      // translate to a Sanity Ball
      const sanityBall = {
        ballTitle: ballToPreview.ballTitle,
        ballType: (_ballToPreview$ballTy = ballToPreview.ballType) === null || _ballToPreview$ballTy === void 0 ? void 0 : _ballToPreview$ballTy.toString(),
        flyer: ballToPreview.flyer,
        // flyer?: SanityImage,
        host: ballToPreview.host,
        categories: ballToPreview.categories,
        description: ballToPreview.description,
        functionStartDate: ballToPreview.functionStartDate,
        functionEndDate: ballToPreview.functionEndDate,
        source: (_ballToPreview$source = ballToPreview.source) === null || _ballToPreview$source === void 0 ? void 0 : _ballToPreview$source.toString(),
        location: {
          locationName: (_ballToPreview$locati = ballToPreview.location) === null || _ballToPreview$locati === void 0 ? void 0 : _ballToPreview$locati.locationName,
          url: (_ballToPreview$locati2 = ballToPreview.location) === null || _ballToPreview$locati2 === void 0 ? void 0 : _ballToPreview$locati2.url,
          country: (_ballToPreview$locati3 = ballToPreview.location) === null || _ballToPreview$locati3 === void 0 ? void 0 : _ballToPreview$locati3.country,
          city: (_ballToPreview$locati4 = ballToPreview.location) === null || _ballToPreview$locati4 === void 0 ? void 0 : _ballToPreview$locati4.city,
          street1: (_ballToPreview$locati5 = ballToPreview.location) === null || _ballToPreview$locati5 === void 0 ? void 0 : _ballToPreview$locati5.street1,
          street2: (_ballToPreview$locati6 = ballToPreview.location) === null || _ballToPreview$locati6 === void 0 ? void 0 : _ballToPreview$locati6.street2,
          state: (_ballToPreview$locati7 = ballToPreview.location) === null || _ballToPreview$locati7 === void 0 ? void 0 : _ballToPreview$locati7.state
        },
        website: ballToPreview.website,
        notifyEmail: ballToPreview.notifyEmail,
        notifyName: ballToPreview.notifyName,
        notifyOnApproval: ballToPreview.notifyOnApproval
      };
      setBall(sanityBall);
    }
  }, [props.ball]);
  const getUrlParams = () => location.search;
  const theme = (0, _styles.useTheme)();
  return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
    children: ball && (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      spacing: 2,
      children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          "data-testid": ball !== null && ball !== void 0 && (_ball$flyer = ball.flyer) !== null && _ball$flyer !== void 0 && _ball$flyer.asset.url ? 'flyer-image' : '',
          item: true,
          xs: 12,
          lg: 8,
          className: classes.heroImage,
          style: {
            backgroundImage: "url(".concat(ball === null || ball === void 0 || (_ball$flyer2 = ball.flyer) === null || _ball$flyer2 === void 0 ? void 0 : _ball$flyer2.asset.url, ")"),
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover'
          },
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            justifyContent: 'space-between',
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              container: true,
              children: (0, _jsxRuntime.jsx)(_material.Button, {
                sx: {
                  paddingX: theme.spacing(4)
                },
                style: {
                  backgroundColor: 'rgba(0,0,0,.5)'
                },
                onClick: () => navigate("".concat(_Routes.RoutesEnum.HOME).concat(getUrlParams())),
                children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                  container: true,
                  item: true,
                  children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    children: (0, _jsxRuntime.jsx)(_iconsMaterial.ChevronLeft, {
                      style: {
                        color: "whitesmoke"
                      }
                    })
                  }), (0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      color: 'white',
                      variant: 'h6',
                      style: {
                        paddingTop: "3px"
                      },
                      children: "Back to Results"
                    })
                  })]
                })
              })
            })
          })
        }), (0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          direction: 'column',
          justifyContent: 'space-between',
          item: true,
          xs: 12,
          md: 4,
          // spacing={2}
          padding: 2,
          children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            direction: 'column',
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                color: 'textSecondary',
                variant: 'h6',
                children: (0, _HTMLUtils.getMonthFromDate)(ball === null || ball === void 0 ? void 0 : ball.functionStartDate).toUpperCase()
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                color: 'textSecondary',
                variant: 'h5',
                children: ball === null || ball === void 0 || (_ball$functionStartDa = ball.functionStartDate) === null || _ball$functionStartDa === void 0 ? void 0 : _ball$functionStartDa.substring(8, 10)
              })
            })]
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              color: 'textSecondary',
              children: (0, _jsxRuntime.jsx)(_material.Link, {
                href: (_ball$website = ball === null || ball === void 0 ? void 0 : ball.website) !== null && _ball$website !== void 0 ? _ball$website : "",
                children: ball === null || ball === void 0 ? void 0 : ball.ballTitle
              })
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              color: 'textSecondary',
              children: "by ".concat(ball === null || ball === void 0 || (_ball$host = ball.host) === null || _ball$host === void 0 ? void 0 : _ball$host.replace(/&amp;/g, '&'))
            })
          })]
        })]
      }), (0, _jsxRuntime.jsx)(_material.Hidden, {
        smDown: true,
        children: (0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          className: classes.favoritesShareContainer,
          alignItems: 'center',
          paddingBottom: 1,
          paddingTop: 2,
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            style: {
              paddingLeft: "16px"
            },
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              color: 'textSecondary',
              children: (0, _jsxRuntime.jsx)(_iconsMaterial.Share, {
                style: {
                  fontSize: '38px'
                }
              })
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              color: 'textSecondary',
              component: 'div'
            })
          })]
        })
      }), (0, _jsxRuntime.jsx)(_material.Hidden, {
        mdUp: true,
        children: (0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          direction: 'column',
          item: true,
          spacing: 3,
          paddingTop: 2,
          paddingBottom: 1.5,
          paddingX: 2,
          style: {
            borderBottom: '1px solid #DBDAE3'
          },
          children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            wrap: 'nowrap',
            zeroMinWidth: true,
            spacing: 2,
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              children: (0, _jsxRuntime.jsx)(_iconsMaterial.CalendarToday, {
                color: 'primary'
              })
            }), (0, _jsxRuntime.jsxs)(_material.Grid, {
              container: true,
              direction: 'column',
              item: true,
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'textSecondary',
                  variant: 'body2',
                  children: "Date and time"
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsxs)(_material.Typography, {
                  color: 'textSecondary',
                  children: [(0, _HTMLUtils.getPrettyDateStr)(ball === null || ball === void 0 ? void 0 : ball.functionStartDate, true), ", ", (0, _HTMLUtils.getPrettyTimeStr)(ball === null || ball === void 0 ? void 0 : ball.functionStartDate)]
                })
              })]
            })]
          }), (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            wrap: 'nowrap',
            zeroMinWidth: true,
            spacing: 2,
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              children: (0, _jsxRuntime.jsx)(_iconsMaterial.GpsFixed, {
                color: 'primary'
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              direction: 'column',
              item: true,
              children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                container: true,
                item: true,
                children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    color: 'textSecondary',
                    variant: 'body2',
                    children: "Location"
                  })
                }), (0, _jsxRuntime.jsxs)(_material.Grid, {
                  container: true,
                  direction: 'column',
                  item: true,
                  children: [(0, _jsxRuntime.jsx)(_material.Typography, {
                    color: 'textSecondary',
                    children: ball === null || ball === void 0 || (_ball$location = ball.location) === null || _ball$location === void 0 ? void 0 : _ball$location.locationName
                  }), (0, _jsxRuntime.jsx)(_material.Typography, {
                    color: 'textSecondary',
                    children: ball === null || ball === void 0 || (_ball$location2 = ball.location) === null || _ball$location2 === void 0 ? void 0 : _ball$location2.street1
                  }), (0, _jsxRuntime.jsx)(_material.Typography, {
                    color: 'textSecondary',
                    children: ball === null || ball === void 0 || (_ball$location3 = ball.location) === null || _ball$location3 === void 0 ? void 0 : _ball$location3.street2
                  }), (0, _jsxRuntime.jsx)(_material.Typography, {
                    color: 'textSecondary',
                    children: "".concat(ball === null || ball === void 0 || (_ball$location4 = ball.location) === null || _ball$location4 === void 0 ? void 0 : _ball$location4.city, ", ").concat(ball === null || ball === void 0 || (_ball$location5 = ball.location) === null || _ball$location5 === void 0 ? void 0 : _ball$location5.state)
                  })]
                })]
              })
            })]
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          direction: 'column',
          item: true,
          xs: 12,
          md: 8,
          className: classes.leftContent,
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            style: {
              marginBottom: theme.spacing(2)
            },
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: 'h5',
              color: 'textSecondary',
              children: "About the Function"
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              color: 'textSecondary',
              component: 'div',
              children: (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                className: classes.ballDescription,
                children: ball === null || ball === void 0 || (_ball$description = ball.description) === null || _ball$description === void 0 ? void 0 : _ball$description.toString()
              })
            })
          }), (ball === null || ball === void 0 || (_ball$categories = ball.categories) === null || _ball$categories === void 0 ? void 0 : _ball$categories.length) > 0 && (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            style: {
              margin: theme.spacing(2, 0)
            },
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              color: 'textSecondary',
              variant: 'h6',
              children: "Categories"
            })
          }), ball === null || ball === void 0 ? void 0 : ball.categories.map((category, index) => (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            children: (0, _jsxRuntime.jsx)(_ClosedCategory.default, {
              showDescription: true,
              category: category,
              keyValue: index
            })
          }, index))]
        }), (0, _jsxRuntime.jsx)(_material.Hidden, {
          mdDown: true,
          children: (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            direction: 'column',
            item: true,
            xs: 4,
            padding: 2,
            spacing: 3,
            children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
              container: true,
              direction: 'column',
              item: true,
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'textSecondary',
                  variant: 'body2',
                  fontWeight: 600,
                  children: "Date and time"
                })
              }), (0, _jsxRuntime.jsxs)(_material.Grid, {
                item: true,
                children: [(0, _jsxRuntime.jsxs)(_material.Typography, {
                  color: 'textSecondary',
                  children: [(0, _HTMLUtils.getPrettyDateStr)(ball === null || ball === void 0 ? void 0 : ball.functionStartDate, true), ",", (0, _HTMLUtils.getPrettyTimeStr)(ball === null || ball === void 0 ? void 0 : ball.functionStartDate)]
                }), (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'textSecondary',
                  component: 'div',
                  children: (ball === null || ball === void 0 ? void 0 : ball.functionEndDate) !== '' && (0, _jsxRuntime.jsxs)("div", {
                    children: [(0, _jsxRuntime.jsx)("div", {
                      className: classes.dateText,
                      children: "-"
                    }), (0, _jsxRuntime.jsx)("div", {
                      className: classes.dateText,
                      children: (0, _HTMLUtils.getPrettyTimeStr)(ball === null || ball === void 0 ? void 0 : ball.functionEndDate)
                    })]
                  })
                })]
              })]
            }), (0, _jsxRuntime.jsxs)(_material.Grid, {
              container: true,
              direction: 'column',
              item: true,
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'textSecondary',
                  variant: 'body2',
                  fontWeight: 600,
                  children: "Location"
                })
              }), (0, _jsxRuntime.jsxs)(_material.Grid, {
                container: true,
                direction: 'column',
                item: true,
                children: [(0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'textSecondary',
                  children: ball === null || ball === void 0 || (_ball$location6 = ball.location) === null || _ball$location6 === void 0 ? void 0 : _ball$location6.locationName
                }), (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'textSecondary',
                  children: "".concat(ball === null || ball === void 0 || (_ball$location7 = ball.location) === null || _ball$location7 === void 0 ? void 0 : _ball$location7.street1)
                }), (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'textSecondary',
                  children: "".concat(ball === null || ball === void 0 || (_ball$location8 = ball.location) === null || _ball$location8 === void 0 ? void 0 : _ball$location8.street2)
                }), (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'textSecondary',
                  children: "".concat(ball === null || ball === void 0 || (_ball$location9 = ball.location) === null || _ball$location9 === void 0 ? void 0 : _ball$location9.city, ", ").concat(ball === null || ball === void 0 || (_ball$location10 = ball.location) === null || _ball$location10 === void 0 ? void 0 : _ball$location10.state)
                })]
              })]
            }), (0, _jsxRuntime.jsxs)(_material.Grid, {
              container: true,
              direction: 'column',
              item: true,
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'textSecondary',
                  variant: 'body2',
                  fontWeight: 600,
                  children: "Share with friends"
                })
              }), (0, _jsxRuntime.jsxs)(_material.Grid, {
                container: true,
                item: true,
                spacing: 2,
                children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    color: 'textSecondary',
                    children: (0, _jsxRuntime.jsx)(_iconsMaterial.Facebook, {})
                  })
                }), (0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    color: 'textSecondary',
                    children: (0, _jsxRuntime.jsx)(_iconsMaterial.LinkedIn, {})
                  })
                }), (0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    color: 'textSecondary',
                    children: (0, _jsxRuntime.jsx)(_iconsMaterial.Twitter, {})
                  })
                }), (0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    color: 'textSecondary',
                    children: (0, _jsxRuntime.jsx)(_iconsMaterial.Email, {})
                  })
                })]
              })]
            }), (0, _jsxRuntime.jsx)(_material.Hidden, {
              mdDown: true,
              children: (0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                justifyContent: 'center',
                item: true
              })
            })]
          })
        })]
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        direction: 'column',
        item: true,
        columnSpacing: 2,
        children: [(ball === null || ball === void 0 ? void 0 : ball.location) && (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)(_BallMapComponent.default, {
            location: ball === null || ball === void 0 ? void 0 : ball.location
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'center',
          paddingTop: 3,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            color: 'textSecondary',
            variant: 'h6',
            children: ball === null || ball === void 0 ? void 0 : ball.ballTitle
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            color: 'textSecondary',
            children: "at"
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            color: 'textSecondary',
            fontStyle: 'oblique',
            children: ball === null || ball === void 0 || (_ball$location11 = ball.location) === null || _ball$location11 === void 0 ? void 0 : _ball$location11.locationName
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            color: 'textSecondary',
            children: "".concat(ball === null || ball === void 0 || (_ball$location12 = ball.location) === null || _ball$location12 === void 0 ? void 0 : _ball$location12.street1, " ").concat(ball === null || ball === void 0 || (_ball$location13 = ball.location) === null || _ball$location13 === void 0 ? void 0 : _ball$location13.city, ", ").concat(ball === null || ball === void 0 || (_ball$location14 = ball.location) === null || _ball$location14 === void 0 ? void 0 : _ball$location14.state)
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'center',
          paddingTop: 2,
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsxs)(_material.Grid, {
              container: true,
              item: true,
              columnSpacing: 3,
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_iconsMaterial.DriveEta, {
                  color: 'primary'
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_iconsMaterial.DirectionsWalk, {
                  color: 'primary'
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_iconsMaterial.DirectionsBus, {
                  color: 'primary'
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_iconsMaterial.DirectionsBike, {
                  color: 'primary'
                })
              })]
            })
          })
        })]
      }), (0, _jsxRuntime.jsx)(_material.Hidden, {
        lgUp: true,
        children: (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          justifyContent: 'center',
          item: true,
          style: {
            padding: theme.spacing(3)
          }
        })
      }), ball.slug && (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        xs: 12,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          children: "Comments"
        })
      })]
    })
  });
};
var _default = exports.default = BallPageBaseNew;