"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _material = require("@mui/material");
var _BallPageBaseNew = _interopRequireDefault(require("./BallPageBaseNew"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 *
 * BallPage
 *
 */

const BallPage = props => {
  // const theme = useTheme()
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    children: [(0, _jsxRuntime.jsx)(_material.Hidden, {
      mdDown: true,
      children: (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        lg: 4,
        xl: 4
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
      children: props.ball && (0, _jsxRuntime.jsx)(_BallPageBaseNew.default, {
        ball: props.ball
      })
    })]
  });
};
var _default = exports.default = BallPage;