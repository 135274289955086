"use strict";

require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _iconsMaterial = require("@mui/icons-material");
var _Theme = _interopRequireDefault(require("../../../theme/Theme"));
var _Routes = require("./enums/Routes.enum");
var _PageContext = _interopRequireDefault(require("../../page-context/PageContext"));
var _AddBallModal = _interopRequireDefault(require("./modal-add-ball/AddBallModal"));
var _FirebaseContext = _interopRequireDefault(require("../../../common/firebase/firebase-context/FirebaseContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const useStyles = exports.useStyles = (0, _makeStyles.default)(theme => ({
  ballInfoButton: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '466px'
    }
    // width: '210px',
  }
}));
const BallToolsSection = props => {
  const classes = useStyles(_Theme.default);
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const firebaseContext = (0, _react.useContext)(_FirebaseContext.default);
  // const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = (0, _react.useState)(false);
  (0, _react.useEffect)(() => {
    console.log(pageContext);
  }, [pageContext]);
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    spacing: 3,
    justifyContent: 'center',
    style: {
      minHeight: "800px",
      paddingTop: "128px",
      paddingLeft: "32px",
      paddingRight: "32px"
    },
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      xs: 12,
      md: 10,
      justifyContent: 'center',
      children: (0, _jsxRuntime.jsx)(_material.Typography, {
        variant: 'h4',
        color: 'textSecondary',
        children: "Ball Info"
      })
    }), (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      spacing: 2,
      children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        direction: 'column',
        alignItems: 'center',
        item: true,
        sm: 12,
        md: 4,
        children: [(0, _jsxRuntime.jsx)(_material.Button, {
          fullWidth: true,
          variant: 'outlined',
          color: 'primary',
          "data-testid": 'add-ball-button',
          style: {
            height: '266px'
          },
          className: classes.ballInfoButton,
          onClick: () => {
            setIsModalOpen(state => !state);
            firebaseContext.ctaClick && firebaseContext.ctaClick('ball-tools', 'ball-submission', pageContext.analyticsId);
          },
          children: (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            direction: 'column',
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              children: (0, _jsxRuntime.jsx)(_iconsMaterial.Add, {
                style: {
                  fontSize: "86px"
                }
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              children: "Ball Submission"
            })]
          })
        }), (0, _jsxRuntime.jsx)(_AddBallModal.default, {
          open: isModalOpen,
          ballToAdd: props.ballToAdd ? _objectSpread(_objectSpread({}, props.ballToAdd), {}, {
            fileUploaded: props.ballFlyerFile
          }) : {
            categories: []
          }
        })]
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        direction: 'column',
        alignItems: 'center',
        item: true,
        sm: 12,
        md: 4,
        children: (0, _jsxRuntime.jsx)(_material.Button, {
          fullWidth: true,
          variant: 'outlined',
          color: 'primary',
          "data-testid": 'search-ball-button',
          style: {
            height: '266px'
          },
          className: classes.ballInfoButton,
          href: "".concat(_Routes.RoutesEnum.SEARCH),
          onClick: () => {
            firebaseContext.ctaClick && firebaseContext.ctaClick('ball-tools', 'ball-search', pageContext.analyticsId);
            // navigate("/"+RoutesEnum.SEARCH)
            // navigate(0)
          },
          children: (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            direction: 'column',
            alignItems: 'center',
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              children: (0, _jsxRuntime.jsx)(_iconsMaterial.Search, {
                style: {
                  fontSize: "86px"
                }
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              children: "Search for a Ball"
            })]
          })
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        direction: 'column',
        alignItems: 'center',
        item: true,
        sm: 12,
        md: 4,
        children: (0, _jsxRuntime.jsx)(_material.Button, {
          "data-testid": 'new-house-button',
          style: {
            height: '266px'
          },
          fullWidth: true,
          variant: 'outlined',
          color: 'primary',
          className: classes.ballInfoButton,
          href: "".concat(_Routes.RoutesEnum.NEW_HOUSE),
          onClick: () => {
            firebaseContext.ctaClick && firebaseContext.ctaClick('ball-tools', 'new-house', pageContext.analyticsId);
            // navigate("/"+RoutesEnum.NEW_HOUSE)
            // navigate(0)
          },
          children: (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            direction: 'column',
            alignItems: 'center',
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              children: (0, _jsxRuntime.jsx)(_iconsMaterial.Add, {
                style: {
                  fontSize: "86px"
                }
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              children: "New House"
            })]
          })
        })
      })]
    })]
  });
};
var _default = exports.default = BallToolsSection;