"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CategoryTypeType = exports.CategoryPrizeType = exports.CategoryNameType = exports.CategoryGenderType = void 0;
var CategoryGenderType;
(function (CategoryGenderType) {
  CategoryGenderType["OTA"] = "OTA";
  CategoryGenderType["FQ"] = "FQ";
  CategoryGenderType["BQ"] = "BQ";
  CategoryGenderType["DRAGS"] = "Drags";
  CategoryGenderType["TRANSMAN"] = "Transman";
  CategoryGenderType["WOMAN"] = "Women";
  CategoryGenderType["FF"] = "FF";
  CategoryGenderType["MF"] = "MF";
})(CategoryGenderType || (exports.CategoryGenderType = CategoryGenderType = {}));
var CategoryTypeType;
(function (CategoryTypeType) {
  CategoryTypeType["GRANDPRIZE"] = "GrandPrize";
  CategoryTypeType["MINIGRANDPRIZE"] = "Mini Grand Prize";
  CategoryTypeType["REGULAR"] = "Regular";
})(CategoryTypeType || (exports.CategoryTypeType = CategoryTypeType = {}));
var CategoryNameType;
(function (CategoryNameType) {
  CategoryNameType["VOGUE"] = "Vogue/Performance";
  CategoryNameType["REALNESS"] = "Realness";
  CategoryNameType["FACE"] = "Face";
  CategoryNameType["RUNWAY"] = "Runway";
  CategoryNameType["BESTDRESSED"] = "Best Dressed";
  CategoryNameType["RWT"] = "RwT";
  CategoryNameType["SEXSIREN"] = "Sex Siren";
  CategoryNameType["BODY"] = "Body";
  CategoryNameType["STREETWEAR"] = "Streetwear";
  CategoryNameType["FOOTWEAR"] = "Foot & Eyewear";
  CategoryNameType["SPECIALTY"] = "Specialty/Other";
})(CategoryNameType || (exports.CategoryNameType = CategoryNameType = {}));
var CategoryPrizeType;
(function (CategoryPrizeType) {
  CategoryPrizeType["MONETARY"] = "Monetary";
  CategoryPrizeType["ONLYTROPHY"] = "Trophy Only";
  CategoryPrizeType["GIFT"] = "Gift";
})(CategoryPrizeType || (exports.CategoryPrizeType = CategoryPrizeType = {}));