"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _material = require("@mui/material");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _react = require("react");
var _ButtonGroupMemberEnum = require("./ButtonGroupMemberEnum");
var _CustomizedThemeContext = _interopRequireDefault(require("../customized-theme-provider/CustomizedThemeContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = (0, _makeStyles.default)(theme => ({
  root: {
    height: "100%",
    width: props => props.width ? "".concat(props.width, "px") : 'unset',
    borderRadius: "0 ".concat(theme.shape.borderRadius, "px ").concat(theme.shape.borderRadius, "px 0"),
    borderTopLeftRadius: props => {
      switch (props.buttonGroupiness) {
        case _ButtonGroupMemberEnum.ButtonGroupMemberEnum.CENTER:
          return "0 !important";
        case _ButtonGroupMemberEnum.ButtonGroupMemberEnum.RIGHT:
          return "0 !important";
        case _ButtonGroupMemberEnum.ButtonGroupMemberEnum.LEFT:
        default:
          return theme.shape.borderRadius;
      }
    },
    borderTopRightRadius: props => {
      switch (props.buttonGroupiness) {
        case _ButtonGroupMemberEnum.ButtonGroupMemberEnum.CENTER:
          return "0 !important";
        case _ButtonGroupMemberEnum.ButtonGroupMemberEnum.LEFT:
          return "0 !important";
        case _ButtonGroupMemberEnum.ButtonGroupMemberEnum.RIGHT:
        default:
          return theme.shape.borderRadius;
      }
    },
    borderBottomRightRadius: props => {
      switch (props.buttonGroupiness) {
        case _ButtonGroupMemberEnum.ButtonGroupMemberEnum.CENTER:
          return "0 !important";
        case _ButtonGroupMemberEnum.ButtonGroupMemberEnum.LEFT:
          return "0 !important";
        case _ButtonGroupMemberEnum.ButtonGroupMemberEnum.RIGHT:
        default:
          return theme.shape.borderRadius;
      }
    },
    borderBottomLeftRadius: props => {
      switch (props.buttonGroupiness) {
        case _ButtonGroupMemberEnum.ButtonGroupMemberEnum.CENTER:
          return "0 !important";
        case _ButtonGroupMemberEnum.ButtonGroupMemberEnum.RIGHT:
          return "0 !important";
        case _ButtonGroupMemberEnum.ButtonGroupMemberEnum.LEFT:
        default:
          return theme.shape.borderRadius;
      }
    }
  }
}));
const LoadingButton = props => {
  var _props$color, _props$variant, _themeContext$customi;
  const themeContext = (0, _react.useContext)(_CustomizedThemeContext.default);
  const theme = (0, _material.useTheme)();
  const classes = useStyles({
    buttonGroupiness: props.groupiness,
    width: props.width,
    isRounded: props.isRounded
  });
  const getProgressContrastColor = () => {
    switch (props.color) {
      case 'primary':
        return theme.palette.primary.main;
      case 'secondary':
        return theme.palette.secondary.main;
      default:
        return '#FFFFFF';
    }
  };
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    item: true,
    style: {
      minHeight: props.isSlim ? "40px" : "60px",
      height: "100%",
      marginRight: "-16px"
    },
    children: (0, _jsxRuntime.jsx)(_material.Button, {
      sx: props.isSlim ? {
        width: '120px'
      } : {},
      style: {
        boxShadow: 'none'
      },
      href: props.href,
      disabled: props.disabled,
      onClick: props.clickHandler,
      className: classes.root,
      fullWidth: !props.width,
      color: (_props$color = props.color) !== null && _props$color !== void 0 ? _props$color : 'primary',
      variant: (_props$variant = props.variant) !== null && _props$variant !== void 0 ? _props$variant : 'contained',
      children: props.isLoading ? (0, _jsxRuntime.jsx)(_material.CircularProgress, {
        style: {
          color: (_themeContext$customi = themeContext.customizedTheme) === null || _themeContext$customi === void 0 ? void 0 : _themeContext$customi.palette.getContrastText(getProgressContrastColor()),
          width: "22px",
          height: "22px"
        }
      }) : props.children
    })
  });
};
var _default = exports.default = LoadingButton;