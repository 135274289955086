"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _clsx = _interopRequireDefault(require("clsx"));
var _Styles = _interopRequireDefault(require("../mackenzies-mind/pages/Styles"));
var _PageContext = _interopRequireDefault(require("../../page-context/PageContext"));
var _BallPage = _interopRequireDefault(require("./ball-page/BallPage"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(theme => ({
  preroot: {
    minHeight: '521px',
    // color: "white",
    // position: "relative",
    padding: theme.spacing(12, 0)
  }
}));
const AWSingleBallPageSection = props => {
  const classes = (0, _Styles.default)({
    bgImage: undefined
  });
  const theClasses = useStyles();
  const pageContext = (0, _react.useContext)(_PageContext.default);
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    className: theClasses.preroot,
    children: (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      container: true,
      className: (0, _clsx.default)(classes.fullSection),
      justifyContent: 'center',
      alignItems: 'center',
      children: (0, _jsxRuntime.jsx)(_BallPage.default, {
        ball: props.ball ? props.ball : pageContext.documentData
      })
    })
  });
};
var _default = exports.default = AWSingleBallPageSection;