"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array.reduce.js");
require("core-js/modules/es.array.sort.js");
require("core-js/modules/es.json.stringify.js");
require("core-js/modules/es.promise.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.includes.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/es.string.split.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _SanityContext = _interopRequireDefault(require("./SanityContext"));
var _client = _interopRequireDefault(require("@sanity/client"));
var _uuid = require("uuid");
var _groqQueries = _interopRequireDefault(require("../groqQueries"));
var _reactQuery = require("@tanstack/react-query");
var _imageUrl = _interopRequireDefault(require("@sanity/image-url"));
var _SearchContext = _interopRequireDefault(require("../../../components/templates/cocktail-flash-cards/search-context/SearchContext"));
var _imageUtils = _interopRequireDefault(require("../../../components/templates/anybody-walking/imageUtils"));
var _clientUtils = _interopRequireDefault(require("../../../components/templates/transform-hw/pages/under-construction-page/clientUtils"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const PLACEHOLDER_URL = "https://placehold.co/";
const SanityProvider = props => {
  const [theSanityClient, setTheSanityClient] = (0, _react.useState)(undefined);
  const [theSanityBartenderClient, setTheSanityBartenderClient] = (0, _react.useState)(undefined);
  const [sanityConfig, setSanityConfig] = (0, _react.useState)();
  const [sanityBartenderConfig, setSanityBartenderConfig] = (0, _react.useState)();
  const [builder, setBuilder] = (0, _react.useState)();
  const [cocktailBuilder, setCocktailBuilder] = (0, _react.useState)();
  (0, _react.useEffect)(() => {
    if (sanityConfig) {
      console.log("sanityConfig initialized properly", sanityConfig);
      setTheSanityClient((0, _client.default)({
        projectId: sanityConfig.projectId,
        dataset: sanityConfig.dataset,
        apiVersion: sanityConfig.apiVersion,
        useCdn: sanityConfig.useCdn
      }));
    } else {
      console.log('no sanity config');
    }
  }, [sanityConfig]);
  (0, _react.useEffect)(() => {
    if (sanityBartenderConfig) {
      // console.log("sanityConfig initialized properly", sanityConfig)
      setTheSanityBartenderClient((0, _client.default)({
        projectId: sanityBartenderConfig.projectId,
        dataset: sanityBartenderConfig.dataset,
        apiVersion: sanityBartenderConfig.apiVersion,
        useCdn: sanityBartenderConfig.useCdn
      }));
    } else {
      console.log('no sanity bartender config');
    }
  }, [sanityBartenderConfig]);
  (0, _react.useEffect)(() => {
    if (theSanityClient !== undefined) {
      // console.log("getting image builders for", theSanityClient)
      setBuilder((0, _imageUrl.default)(theSanityClient));
    }
  }, [theSanityClient]);
  (0, _react.useEffect)(() => {
    if (theSanityBartenderClient !== undefined) {
      // console.log("getting image builders for bartender", theSanityBartenderClient)
      setCocktailBuilder((0, _imageUrl.default)(theSanityBartenderClient));
    }
  }, [theSanityBartenderClient]);
  const initSanity = (projectId, dataset, apiVersion, useCdn, bartenderProjId, bartenderDataset) => {
    // console.log("initializing sanity in provider", projectId, dataset, apiVersion, useCdn)
    setSanityConfig({
      projectId,
      dataset,
      apiVersion,
      useCdn
    });
    setSanityBartenderConfig({
      projectId: bartenderProjId,
      dataset: bartenderDataset,
      useCdn,
      apiVersion
    });
  };
  const fetchLandingPage = slug => {
    return theSanityClient.fetch("*[_type==\"landingPage\" && slug.current == $slug]{\n          title,\n          slug,\n          mainImage{\n            asset->{\n              _id,\n              url\n             }\n           },\n         body,\n         welcomeMessage,\n         headerText,\n         \"form\":abForm->{instructionBlock, abFormType->{title}},\n         utmSource,\n         utmMedium,\n         utmCampaign,\n         publishedAt\n       }", {
      slug
    }).then(data => {
      return data[0];
    });
  };
  const fetchBlogPost = slug => {
    return theSanityClient.fetch("*[_type==\"post\" && slug.current == $slug]{\n          title,\n          slug,\n          mainImage{\n            asset->{\n              _id,\n              url\n             }\n           },\n          mainImageCaption,\n          body,\n          category->{\n            title,\n            description,\n            color\n          },\n          _createdAt\n       }", {
      slug
    }).then(data => {
      return data[0];
    });
  };
  const fetchMuiTheme = slug => {
    return theSanityClient.fetch("*[_type==\"MuiTheme\" && slug.current == $slug]", {
      slug
    }).then(data => {
      return data[0];
    });
  };
  const fetchLatestBlogPostPreview = () => {
    return theSanityClient.fetch("*[_type==\"post\"] | order(publishedAt desc){\n          title,\n          slug,\n          mainImage{\n            asset->{\n              _id,\n              url\n             }\n           },\n         \"snippet\": pt::text(body),\n         category->{\n           title,\n           description,\n           color\n         },\n         _createdAt\n       }[0]", {}).then(data => {
      return data;
    });
  };
  const fetchAllBlogPostPreviews = () => {
    return theSanityClient.fetch("*[_type==\"post\"] | order(publishedAt desc){\n          title,\n          slug,\n          mainImage{\n            asset->{\n              _id,\n              url\n             }\n           },\n          mainImageCaption,\n         \"snippet\": pt::text(body),\n         category->{\n           title,\n           color\n         },\n         _createdAt\n       }", {}).then(data => {
      return data;
    });
  };
  const fetchBlogPostPreviewsByCategory = category => {
    return theSanityClient.fetch("*[_type==\"post\" && category._ref == *[_type == \"category\" && title == $category][0]._id ] | order(publishedAt desc){\n          title,\n          slug,\n          mainImage{\n            asset->{\n              _id,\n              url\n             }\n           },\n         \"snippet\": pt::text(body),\n         category->{\n           title,\n           color\n         },\n         _createdAt\n       }", {
      category
    }).then(data => {
      return data;
    });
  };
  const fetchBlogPostPreviewsByKeyword = keyword => {
    const processedKeyword = '*' + keyword + '*';
    return theSanityClient.fetch("*[_type==\"post\" && [title, pt::text(body)] match $processedKeyword ] | order(publishedAt desc){\n          title,\n          slug,\n          mainImage{\n            asset->{\n              _id,\n              url\n             }\n           },\n         \"snippet\": pt::text(body),\n         category->{\n           title,\n           color\n         },\n         _createdAt\n       }", {
      processedKeyword
    }).then(data => {
      return data;
    });
  };
  const fetchLandingPageHeaderMenu = () => {
    return theSanityClient.fetch("*[_type==\"menuContainer\" && slug.current == 'header-menu']{\n          title,\n          slug,\n         \"menuItems\": subMenus[]->{slug, displayText, links[] -> }\n       }").then(data => {
      return data[0];
    });
  };
  const fetchLandingPageFooterMenu = footerSlug => {
    const slug = footerSlug !== null && footerSlug !== void 0 ? footerSlug : 'footer-menu';
    return theSanityClient.fetch("*[_type==\"menuContainer\" && slug.current == $slug]{\n          ".concat(_groqQueries.default.MENUGROUPCONTAINER, "\n       }"), {
      slug
    }).then(data => {
      return data[0];
    });
  };
  const fetchBlogCategories = () => {
    return theSanityClient.fetch("*[_type == \"category\"]{\n        title,\n        description,\n        color\n    }").then(data => {
      return data;
    });
  };
  const fetchBlogGroup = title => {
    return theSanityClient.fetch("*[_type == \"postGroup\" && title == $title]{\n          title,\n          posts[] -> { title, slug }\n       }[0]", {
      title
    }).then(data => {
      return data;
    });
  };
  const fetchWhySwitch = async id => {
    return theSanityClient.fetch("*[_id == $id]{\n          imageSrc {\n            asset->{\n              altText,\n              description\n             }\n          }\n       }[0]", {
      id
    }).then(data => {
      const whySwitchSection = data;
      console.log("why switch Section data", whySwitchSection);
      return data;
    });
  };
  const fetchRef = sanityRef => {
    return theSanityClient.fetch("*[_id == $reference][0]", {
      reference: sanityRef._ref
    }).then(data => {
      return data;
    });
  };
  const fetchRefs = async sanityRefs => {
    console.log("get these refs", sanityRefs);
    let servicesRefs = [];
    let otherContentRefs = [];
    sanityRefs === null || sanityRefs === void 0 || sanityRefs.forEach(sanityRef => {
      console.log("does match?", sanityRef._type, _groqQueries.default.SANITY_TYPES_ENUM.SERVICE, sanityRef._type === _groqQueries.default.SANITY_TYPES_ENUM.SERVICE);
      if (sanityRef._type == _groqQueries.default.SANITY_TYPES_ENUM.SERVICE) {
        servicesRefs.push(sanityRef._ref);
      }
      otherContentRefs.push(sanityRef._ref);
    });
    console.log("Division of labor", sanityRefs, otherContentRefs);
    const servicesQuery = "*[_type == ".concat(_groqQueries.default.SANITY_TYPES_ENUM.SERVICE, " && _id in $references]{\n    ").concat(_groqQueries.default.SERVICE, "\n  }");
    const services = await theSanityClient.fetch(servicesQuery, {
      references: servicesRefs
    });
    const otherRefs = await theSanityClient.fetch("*[_id in $references]", {
      references: otherContentRefs
    });
    console.log("Done Division of labor", services, otherRefs);
    return Promise.all([...services, otherRefs]);
  };
  // const useFetchPageBySlugQuery = (slug: string) => {
  //     // console.log("slug", slug)
  //     return useQuery(
  //         ['fetchPageBySlug', slug],
  //         async ({queryKey}) => {
  //             const [_, pageSlug] = queryKey
  //
  //             if (pageSlug && pageSlug.length > 0) {
  //                 return theSanityClient?.fetch(
  //                     `*[slug.current == $pageSlug && _type == "homePage"]{
  //       ${groqQueries.HOMEPAGE}
  //     }`, {pageSlug})
  //                     .then((result: any) => {
  //                         if (result.length === 0) {
  //                             return Promise.reject(Error("No results returned"))
  //                         }
  //                         if (result.length >= 1) {
  //
  //                             return result[0]
  //                         }
  //                     }).catch(() => {
  //                         return Promise.reject(Error("Sanity Error getting pageSlug " + pageSlug))
  //                     })
  //             } else {
  //                 return Promise.reject(Error("No page slug passed"))
  //             }
  //         },
  //         {}
  //     );
  // }
  const fetchDocumentByTypeAndSlugQuery = async (documentType, documentSlug) => {
    // console.log(`Retrieving document type:${documentType} with slug ${documentSlug}`)
    if (documentType && documentType.length > 0 && documentSlug && documentSlug.length > 0) {
      return theSanityClient === null || theSanityClient === void 0 ? void 0 : theSanityClient.fetch("*[slug.current == $documentSlug && _type == $documentType]{\n          ...,\n          flyer {\n            asset->{\n              _id,\n              url,\n              altText\n             }\n          }\n        }", {
        documentType,
        documentSlug
      }).then(result => {
        if (result.length === 0) {
          return Promise.reject(Error("No document returned"));
        }
        if (result.length >= 1) {
          // console.log("got document?", result[0])
          return result[0];
        }
      }).catch(() => {
        console.log("could not get document for slug", documentSlug);
        return Promise.reject(Error("Sanity Error getting document with slug " + documentSlug));
      });
    }
  };
  const fetchPageBySlugQuery = async pageSlug => {
    // console.log("Retrieving page with sanityClient", theSanityClient)
    if (pageSlug && pageSlug.length > 0) {
      return theSanityClient === null || theSanityClient === void 0 ? void 0 : theSanityClient.fetch("*[slug.current == $pageSlug && _type == \"homePage\"]{\n          ".concat(_groqQueries.default.HOMEPAGE, "\n        }"), {
        pageSlug
      }).then(result => {
        if (result.length === 0) {
          return Promise.reject(Error("No results returned"));
        }
        if (result.length >= 1) {
          console.log("got home?", result[0]);
          return result[0];
        }
      }).catch(() => {
        console.log("could not get page for slug", pageSlug);
        return Promise.reject(Error("Sanity Error getting pageSlug " + pageSlug));
      });
    } else {
      return Promise.reject(Error("No page slug passed"));
    }
  };
  const useFetchMenuBySlugQuery = menuSlug => {
    console.log("fetching menu with slug", menuSlug);
    return (0, _reactQuery.useQuery)({
      queryKey: [menuSlug],
      queryFn: () => {
        return theSanityClient.fetch("*[slug.current == $menuSlug]{\n          ".concat(_groqQueries.default.MENUGROUPCONTAINER, "\n       }"), {
          menuSlug: menuSlug !== null && menuSlug !== void 0 ? menuSlug : 'header-menu'
        }).then(data => {
          return data[0];
        });
      }
    });
  };
  const useFetchMenuByRefQuery = headerMenuRef => {
    var _headerMenuRef$_ref;
    console.log("fetching menu with ref", headerMenuRef);
    const menuId = (_headerMenuRef$_ref = headerMenuRef === null || headerMenuRef === void 0 ? void 0 : headerMenuRef._ref) !== null && _headerMenuRef$_ref !== void 0 ? _headerMenuRef$_ref : ['no-id'];
    return (0, _reactQuery.useQuery)({
      queryKey: [...menuId],
      queryFn: () => {
        return theSanityClient.fetch("*[_id == $menuId && _type == \"menuContainer\"]{\n                          ".concat(_groqQueries.default.MENUGROUPCONTAINER, "\n                        }"), {
          menuId
        }).then(result => {
          if (result.length === 0) {
            return Promise.reject(Error("No results returned"));
          }
          return result[0];
        }).catch(() => {
          return Promise.reject(Error("Sanity Error getting pageSlug " + menuId));
        });
      }
    });
  };
  //
  // const fetchLandingPageFooterMenu = (footerSlug?: string): Promise<SanityMenuContainer> => {
  //     const slug = footerSlug ?? 'footer-menu'
  //
  //     return theSanityClient
  //         .fetch(
  //             `*[_type=="menuContainer" && slug.current == $slug]{
  //           ${GroqQueries.MENUGROUPCONTAINER}
  //        }`, {slug}
  //         )
  //         .then((data: SanityMenuContainer[]) => {
  //             return data[0]
  //         })
  // }
  const useFetchRefsQuery = refs => {
    return (0, _reactQuery.useQuery)({
      queryKey: ['fetchRefs'],
      queryFn: async () => {
        return fetchRefs(refs).then(results => {
          if (results.length === 0) {
            console.log("whew! after fetching a bunch of refs ", results);
          }
          return results;
        }).catch(e => {
          console.log("error getting services", e);
          return [];
        });
      }
    });
  };
  const useFetchServicesQuery = pageSlug => {
    return (0, _reactQuery.useQuery)({
      queryKey: ['fetchServices'],
      queryFn: async () => {
        console.log("fetchings services", pageSlug);
        const serviceSlug = pageSlug;
        let serviceSlugClause = '';
        if (serviceSlug) {
          serviceSlugClause = " && slug.current != $serviceSlug";
        }
        const query = "*[_type == \"transformServiceItem\"".concat(serviceSlugClause, "]{\n                     ").concat(_groqQueries.default.SERVICE, "\n                    }");
        const params = serviceSlug ? {
          serviceSlug: serviceSlug
        } : {};
        return theSanityClient.fetch(query, params).then(results => {
          if (results.length === 0) {
            console.log("No Services present");
          }
          return results;
        }).catch(e => {
          console.log("error getting services", e);
          return [];
        });
      }
    });
  };
  const fullTextSearch = (textToSearch, pageId) => {
    console.log("the page id", pageId);
    return theSanityClient.fetch("*[\n            ([\n                title, \n                careerTitle,\n                introduction,\n                name, \n                email,\n                contentText, \n                contentTexts, \n                contentTitle, \n                contentPreTitle, \n                highlightedAmenitiesTitle, \n                highlightedAmenitiesText, \n                contentWelcomeMessage,\n                contentSummaryTitle,\n                contentSummaryTexts,\n                videoUrl,\n            ] match '*".concat(textToSearch, "*') && references('").concat(pageId, "')]{\n                ..., \n                \"skillsUsed\" : skillsUsed[]->,\n                \"skills\" : skills[]->,\n                \"skillsHighlighted\": skillsHighlighted[]->,\n            }")).then(data => {
      console.log("results from full text search", data, textToSearch, pageId);
      return data;
    });
  };
  const skillReferenceSearch = (skill, pageId) => {
    return theSanityClient.fetch("*[references($searchText) && references('".concat(pageId, "')]{\n                ..., \n                \"skillsHighlighted\": skillsHighlighted[]->,\n                \"skillsUsed\" : skillsUsed[]->,\n                \"skills\":skills[]->,\n            }"), {
      searchText: skill._id,
      pageId: pageId
    }).then(data => {
      data.sort((left, right) => {
        if (left._type === right._type) {
          return 0;
        }
        if (left._type > right._type) return 1;
        return -1;
      });
      console.log("results from skills reference search", data, skill._id, pageId);
      return data;
    });
  };
  const fetchHomePage = () => {
    return theSanityClient.fetch("*[_type==\"abHomePage\" && slug.current == \"home\"]{\n          slug,\n          heroImage->{\n            slug,\n            mainImage{\n              asset->{\n                _id,\n                url\n              }\n            },\n            gradient\n          },\n          introduction,\n          specializationsMenuGroup->{\n            menuGroupTitle,\n            links[] -> {displayText, url}\n          },\n          weWorkWithSection {\n            title,\n            sectionHeader,\n            description,\n            companyPartnerLogos[]{\n              asset->{\n                _id,\n                url\n              }\n            }\n          },\n          ourServicesSection {\n            title,\n            sectionHeader,\n            serviceList[]{\n              title,\n              description\n            }\n          },\n          solutions {\n            sectionHeader,\n            solutionList[] {\n              title,\n              description\n            }\n          }\n       }[0]").then(data => {
      return data;
    });
  };
  const fetchCommunityPage = () => {
    return theSanityClient.fetch("*[_type==\"communityPage\" && slug.current == \"community\"]{\n          slug,\n          titleText,\n          mainImage{\n            asset->{\n              _id,\n              url\n            }\n          },\n          gradient,\n          introduction,\n          howWeGrowBrandsTitle,\n          howWeGrowBrandsContent{\n            content\n          },\n          weWorkWithSection {\n            title,\n            sectionHeader,\n            description,\n            companyPartnerLogos[]{\n              asset->{\n                _id,\n                url\n              }\n            }\n          },\n          callToAction\n       }[0]").then(data => {
      return data;
    });
  };
  const fetchOurStoryPage = () => {
    return theSanityClient.fetch("*[_type==\"ourStoryPage\" && slug.current == \"our-story\"]{\n          slug,\n          titleText,\n          mainImage{\n            asset->{\n              _id,\n              url\n            }\n          },\n          gradient,\n          introduction,\n          storyStartTitle,\n          storyStartLeft1,\n          storyStartLeft2,\n          storyStartLeft3,\n          storyStartRightImage{\n              asset->{\n                _id,\n                url\n              }\n            },\n          brandQuotesTitle,\n          brandQuotesList[]{\n            title,\n            description,\n            image{\n              asset->{\n                _id,\n                url\n              }\n            }\n          },\n          howWeGrowBrandsTitle,\n          howWeGrowBrandsContent{\n            content\n          }\n       }[0]").then(data => {
      return data;
    });
  };
  const fetchHomePageSpecializationsMenu = () => {
    return theSanityClient.fetch("*[_type==\"menuGroup\" && slug.current == 'specializations']{\n          slug,\n          displayText,\n         \"links\": links[]->{displayText, url}\n       }[0]").then(data => {
      return data;
    });
  };
  const fetchImageCarousel = slug => {
    return theSanityClient.fetch("*[_type==\"imageCarousel\" && slug.current == $slug]{\n          title,\n          slug,\n          images[] -> { title, mainImage{\n            asset->{\n              _id,\n              url\n             }\n           }}\n       }[0]", {
      slug
    }).then(data => {
      return data;
    });
  };
  const fetchEvergreenPage = slug => {
    return theSanityClient.fetch("*[_type==\"abEvergreenPage\" && slug.current == $slug]{\n          title,\n          mainImage{\n            asset->{\n              _id,\n              url\n            }\n          },\n          pageContent {\n            content\n          }\n       }[0]", {
      slug
    }).then(data => {
      return data;
    });
  };
  const urlFor = source => {
    if (!source) {
      return undefined;
    }
    return builder === null || builder === void 0 ? void 0 : builder.image(source);
  };
  const cocktailUrlFor = source => {
    return cocktailBuilder === null || cocktailBuilder === void 0 ? void 0 : cocktailBuilder.image(source);
  };
  const useFetchAllFlashCards = () => {
    console.log("fetching cocktails");
    return (0, _reactQuery.useQuery)({
      queryKey: ["all-cocktails"],
      queryFn: () => {
        return theSanityBartenderClient.fetch("*[_type == \"Cocktail\" && isDisabled != true]{\n          ".concat(_groqQueries.default.COCKTAIL, "\n       }")).then(data => {
          return data;
        });
      }
    });
  };
  const useFetchMyCocktails = () => {
    console.log("fetching my cocktails ingredient breakdown");
    return (0, _reactQuery.useQuery)({
      queryKey: ["cocktails-ingredient-breakdown"],
      queryFn: () => {
        return theSanityBartenderClient.fetch("{\n                              \"barInventory\": *[_type == \"BarInventory\" && slug.current=='the-drinkery']{\n                              \"theBar\":theBar[]->\n                              },\n                                      \"cocktailWithIngredients\":*[_type == \"Cocktail\" && isDisabled != true]{\n                                       ".concat(_groqQueries.default.COCKTAIL, ",\n                                      \"cocktailIngredientIds\": \n                                         array::compact(\n                                           [\n                                             ...garnish[]->,\n                                             ...mixingGlass[]->, \n                                             ...mixingGlass[].ingredient->\n                                           ]._id\n                                        )\n                                    \n                                \n                                      }  \n                              \n                                  \n                            }")).then(data => {
          var _data$0$barInventory;
          const theBarInventoryIngredientIds = (_data$0$barInventory = data[0].barInventory) === null || _data$0$barInventory === void 0 ? void 0 : _data$0$barInventory.theBar.map(val => {
            return val._id;
          });
          const theResults = data[0].cocktailWithIngredients.reduce((acc, val) => {
            let isAvailable = true;
            val.cocktailIngredientIds.forEach(ingredientId => {
              if (!(ingredientId in theBarInventoryIngredientIds)) {
                isAvailable = false;
              }
            });
            if (isAvailable) {
              acc.push(val);
            }
          }, []);
          return theResults;
        });
      }
    });
  };
  const useFetchAllBarIngredients = () => {
    return (0, _reactQuery.useQuery)({
      queryKey: ["all-bar-ingredients"],
      queryFn: () => {
        return theSanityBartenderClient.fetch("*[_type == \"Ingredient\"]{\n          ".concat(_groqQueries.default.INGREDIENT, "\n       }")).then(data => {
          return data;
        });
      }
    });
  };
  // const useFetchMyBarIngredients = () => {
  //     const pageContext = useContext(PageContext)
  //     return useQuery(
  //         ["my-bar-ingredients"],
  //         () => {
  //             return fetchMyBarIngredients(pageContext.barInventorySlug)
  //         }
  //     );
  // }
  const fetchMyBarIngredients = barInventorySlug => {
    if (barInventorySlug === undefined || barInventorySlug === null) return Promise.resolve([]);else return theSanityBartenderClient.fetch("*[_type == \"BarInventory\" && slug.current=='".concat(barInventorySlug, "']{\n                    ...,\n                    \"theBar\": theBar[]->\n       }")).then(data => {
      console.log("the ingredients and garnishes from my bar", barInventorySlug, data);
      if (data !== undefined && data[0] !== undefined && data[0].theBar !== undefined) return Promise.resolve(data[0].theBar);else return Promise.resolve([]);
    });
  };
  const useFetchAllLiquorTypes = () => {
    return (0, _reactQuery.useQuery)({
      queryKey: ["all-liquor-types"],
      queryFn: () => {
        return theSanityBartenderClient.fetch("*[_type == \"LiquorType\"]{\n          ".concat(_groqQueries.default.INGREDIENT, "\n       }")).then(data => {
          return data;
        });
      }
    });
  };
  const useFetchFilteredIngredients = () => {
    const searchContext = (0, _react.useContext)(_SearchContext.default);
    const liquorTypes = searchContext.searchFilters;
    return (0, _reactQuery.useQuery)({
      queryKey: ["filter-bar-ingredients-by-liq-type"],
      queryFn: () => {
        if (liquorTypes !== undefined && liquorTypes.length > 0) return theSanityBartenderClient.fetch("*[_type == \"Ingredient\" && references($liquorTypeId)]{\n              ".concat(_groqQueries.default.INGREDIENT, "\n           }"), {
          liquorTypeId: liquorTypes
        }).then(data => {
          return data;
        });
        return theSanityBartenderClient.fetch("*[_type == \"Ingredient\"]{\n              ".concat(_groqQueries.default.INGREDIENT, "\n           }")).then(data => {
          return data;
        });
      }
    });
  };
  const useFetchMyFilteredIngredients = () => {
    const searchContext = (0, _react.useContext)(_SearchContext.default);
    const liquorTypes = searchContext.searchFilters;
    return (0, _reactQuery.useQuery)({
      queryKey: ["filter-my-bar-ingredients-by-liq-type"],
      queryFn:
      // @ts-ignore
      () => {
        if (liquorTypes !== undefined && liquorTypes.length > 0) return theSanityBartenderClient.fetch("*[ _type == \"BarInventory\"]{\n                                  ...,\n                                  \"theBarLiquorTypes\": *[ _type == \"Ingredient\" && _id in ^.theBar[]._ref && references($liquorTypeId)]{\n                                    liquorType->,\n                                    ...\n                                  }\n                                }", {
          liquorTypeId: liquorTypes
        }).then(data => {
          var _data$;
          console.log("Supposed to be response", (_data$ = data[0]) === null || _data$ === void 0 ? void 0 : _data$.theBarLiquorTypes);
          // const response = data[0].theBarLiquorTypes.reduce((accumulated:SanityLiquorType[], value)=>{
          //     const findType = accumulated.find((findingValue:SanityLiquorType)=>{
          //         console.log("checking id", findingValue?._id, value?.liquorType?._id)
          //         if(value.liquorType && (findingValue._id === value?.liquorType?._id)){
          //             return findingValue
          //         }
          //         return undefined
          //     })
          //
          //     if(!findType && value.liquorType) {
          //         console.log("pushing",value.liquorType)
          //         accumulated.push(value.liquorType)
          //
          //     }
          //     console.log("acc", accumulated)
          //
          //     return accumulated
          // },[])
          console.log("Supposed to be response", data[0].theBarLiquorTypes);
          return data[0].theBarLiquorTypes;
        });
        return theSanityBartenderClient.fetch("*[_type == \"BarInventory\" && slug.current=='".concat(process.env.REACT_APP_BAR_INVENTORY_SLUG, "']{\n              ...,\n              \"theBar\":theBar[]->\n           }")).then(data => {
          var _data$2;
          return (_data$2 = data[0]) === null || _data$2 === void 0 ? void 0 : _data$2.theBar;
        });
      }
    });
  };
  const useFetchFilteredCocktails = () => {
    const searchContext = (0, _react.useContext)(_SearchContext.default);
    const liquorTypes = searchContext.searchFilters;
    // include the search string in the search
    return (0, _reactQuery.useQuery)({
      queryKey: ["filter-bar-ingredients-by-liq-type"],
      queryFn: () => {
        if (liquorTypes !== undefined && liquorTypes.length > 0) return theSanityBartenderClient.fetch("*[_type == \"Cocktail\" && references($liquorTypeId) && isDisabled != true]{\n              ".concat(_groqQueries.default.COCKTAIL, "\n           }"), {
          liquorTypeId: liquorTypes
        }).then(data => {
          return data;
        });
        return theSanityBartenderClient.fetch("*[_type == \"Cocktail\" && isDisabled != true]{\n              ".concat(_groqQueries.default.COCKTAIL, "\n           }")).then(data => {
          return data;
        });
      }
    });
  };
  const useFetchSearchedCocktails = () => {
    // const liquorTypes = searchContext.searchFilters
    // console.log("fetching cocktails filtered by liquor type ", liquorTypes)
    const searchContext = (0, _react.useContext)(_SearchContext.default);
    // const liquorTypes = searchContext.searchFilters
    return (0, _reactQuery.useQuery)({
      queryKey: ["search-cocktails-by-criteria"],
      queryFn: () => {
        const searchString = searchContext.searchString;
        //  if(liquorTypes && liquorTypes.length > 0 )
        //      return sanityClient
        //          .fetch(
        //              `*[_type == "Ingredient" && references($liquorTypeId)]{
        //    ${groqQueries.INGREDIENT}
        // }`,{
        //                  liquorTypeId:liquorTypes
        //              })
        //          .then((data: SanityCocktailIngredient[]) => {
        //              return data
        //          })
        if (searchString === undefined) return theSanityBartenderClient.fetch("*[_type == \"Cocktail\" && isDisabled != true]{\n              ".concat(_groqQueries.default.COCKTAIL, "\n           }")).then(data => {
          return data;
        });
        if (searchString.length > 0) return theSanityBartenderClient.fetch("*[_type == \"Cocktail\" && \n                                title match \"*$searchString*\" \n                                && isDisabled != true\n                             ]{\n              ".concat(_groqQueries.default.COCKTAIL, "\n           }"), {
          searchString
        }).then(data => {
          return data;
        });
        return undefined;
      }
    });
  };
  const getProduct = async (searchString, searchFilters, ingredientFilters, isAndSearch) => {
    // const [_, searchString, searchFilters] = queryKey
    const liquorTypes = searchFilters;
    const requiredIngredients = ingredientFilters;
    let queryParams = {};
    let searchStringClause = "";
    if (searchString && searchString.length > 0) {
      searchStringClause = " && title match \"*".concat(searchString, "*\"");
      // queryParams = {
      //     ...queryParams,
      // }
    }
    let liquorTypesClause = "";
    if (liquorTypes && liquorTypes.length > 0) {
      liquorTypesClause = " && references(*[references($liquorId)]._id)";
      queryParams = _objectSpread(_objectSpread({}, queryParams), {}, {
        liquorId: liquorTypes
      });
    }
    let ingredientsClause = undefined;
    if (requiredIngredients && requiredIngredients.length > 0) {
      if (!ingredientsClause) ingredientsClause = "";
      ingredientsClause = requiredIngredients.reduce((preClause, reqIngredient, index) => {
        preClause += "(references(*[references('".concat(reqIngredient, "')]._id) || references('").concat(reqIngredient, "'))");
        if (index < requiredIngredients.length - 1) {
          if (isAndSearch) {
            preClause += " && ";
          } else {
            preClause += " || ";
          }
        }
        return preClause;
      }, "");
      ingredientsClause = " && (".concat(ingredientsClause, ")");
    }
    return theSanityBartenderClient.fetch("*[_type == \"Cocktail\"  && isDisabled != true".concat(searchStringClause).concat(ingredientsClause ? ingredientsClause : liquorTypesClause, "]{\n              ").concat(_groqQueries.default.COCKTAIL, "\n           }"), queryParams).then(data => {
      return data;
    });
  };
  const getMyProduct = async (searchString, searchFilters, ingredientFilters, isAndSearch) => {
    // const [_, searchString, searchFilters] = queryKey
    const liquorTypes = searchFilters;
    const requiredIngredients = ingredientFilters;
    let queryParams = {};
    let searchStringClause = "";
    if (searchString && searchString.length > 0) {
      searchStringClause = " && title match \"*".concat(searchString, "*\"");
      // queryParams = {
      //     ...queryParams,
      // }
    }
    let liquorTypesClause = "";
    if (liquorTypes && liquorTypes.length > 0) {
      liquorTypesClause = " && references(*[references($liquorId)]._id)";
      queryParams = _objectSpread(_objectSpread({}, queryParams), {}, {
        liquorId: liquorTypes
      });
    }
    let ingredientsClause = undefined;
    if (requiredIngredients && requiredIngredients.length > 0) {
      if (!ingredientsClause) ingredientsClause = "";
      ingredientsClause = requiredIngredients.reduce((preClause, reqIngredient, index) => {
        preClause += "(references(*[references('".concat(reqIngredient, "')]._id) || references('").concat(reqIngredient, "'))");
        if (index < requiredIngredients.length - 1) {
          if (isAndSearch) {
            preClause += " && ";
          } else {
            preClause += " || ";
          }
        }
        return preClause;
      }, "");
      ingredientsClause = " && (".concat(ingredientsClause, ")");
    }
    return theSanityBartenderClient.fetch("{\n                              \"barInventory\": *[_type == \"BarInventory\" && slug.current=='the-drinkery']{\n                              \"theBar\":theBar[]->\n                              },\n                              \"cocktailWithIngredients\":*[_type == \"Cocktail\" && isDisabled != true".concat(searchStringClause).concat(ingredientsClause ? ingredientsClause : liquorTypesClause, "]{\n                               ").concat(_groqQueries.default.COCKTAIL, "\n                              \"cocktailIngredientIds\": \n                                 array::unique(array::compact(\n                                   [\n                                     ...garnish[]->,\n                                     ...mixingGlass[]->, \n                                     ...mixingGlass[].ingredient->\n                                   ]._id\n                                ))\n                                 }  \n                              }"), queryParams).then(data => {
      var _data$barInventory$;
      const cocktailWithIngredients = data.cocktailWithIngredients;
      const theBarInventoryIngredientIds = (_data$barInventory$ = data.barInventory[0]) === null || _data$barInventory$ === void 0 ? void 0 : _data$barInventory$.theBar.map(val => {
        return val._id;
      });
      const theResults = cocktailWithIngredients.reduce((acc, val) => {
        let isAvailable = true;
        val.cocktailIngredientIds.forEach(ingredientId => {
          if (!theBarInventoryIngredientIds.includes(ingredientId)) {
            isAvailable = false;
          }
        });
        if (isAvailable) {
          acc.push(val);
        }
        return acc;
      }, []);
      return theResults;
    });
  };
  const placeholderOrImage = (imageSrc, placeHolderWidth, placeHolderHeight, text) => {
    let theUrl = "";
    if (imageSrc) {
      var _urlFor$url, _urlFor;
      theUrl = (_urlFor$url = (_urlFor = urlFor(imageSrc)) === null || _urlFor === void 0 ? void 0 : _urlFor.url()) !== null && _urlFor$url !== void 0 ? _urlFor$url : "";
    } else {
      theUrl = getPlaceholderImageUrl(placeHolderWidth, placeHolderHeight, text);
    }
    return theUrl;
  };
  const getPlaceholderImageUrl = (width, height, text) => {
    let theUrl = PLACEHOLDER_URL;
    if (width) {
      if (height) {
        theUrl += "".concat(width, "x").concat(height);
      } else {
        theUrl += "".concat(height, "x").concat(height);
      }
    } else {
      theUrl += "".concat(height, "x").concat(height);
    }
    if (text) {
      theUrl += "?text=".concat(text.replace(" ", "+"));
    }
    return theUrl;
  };
  const getCheckinBySlug = slug => theSanityClient.fetch("*[_type==\"checkinPage\" && slug.current == $slug]{\n          _id,\n          slug,\n          title,\n          shortUrl,\n          ball->{\n            flyer{\n              asset->{\n                _id,\n                url\n              }\n            },\n            ballTitle,\n            functionStartDate,\n            location,\n            slug\n          },\n          houseSubmission,\n          checkinList[]->{\n            _createdAt,\n            firstname,\n            lastname,\n            email,\n            business,\n            businessUrl,\n            houseSubmission\n          },\n       }", {
    slug
  }).then(data => data[0]);
  const fetchBall = ballId => theSanityClient.fetch("*[_type==\"ball\" && _id == $ballId]{\n          _id,\n          ballTitle,\n          host,\n          approval,\n          slug,\n          flyer{\n            asset->{\n              _id,\n              url\n             }\n           },\n         categories,\n         miniGrandPrize,\n         grandPrize,\n         ballType,\n         createdBy,\n         description,\n         functionStartDate,\n         functionEndDate,\n         region,\n         source,\n         location,\n         _createdAt,\n         eventbriteId\n       }", {
    ballId
  }).then(data => data[0]);
  const getBallBySlug = slug => {
    const queryString = "*[_type==\"ball\" && slug.current == \"".concat(slug, "\"]{\n          _id,\n          ballTitle,\n          host,\n          approval,\n          slug,\n          flyer{\n            asset->{\n              _id,\n              url\n             }\n           },\n         categories,\n         miniGrandPrize,\n         grandPrize,\n         ballType,\n         createdBy,\n         description,\n         functionStartDate,\n         functionEndDate,\n         region,\n         source,\n         location,\n         _createdAt,\n         eventbriteId\n       }");
    console.log('getting the slug', slug, queryString);
    return theSanityClient.fetch(queryString).then(data => data[0]);
  };
  const createBall = ball => {
    return fetch("/create-ball", {
      method: 'POST',
      body: JSON.stringify(ball)
    }).then(response => {
      return _clientUtils.default.processResponse(response, 'BallCreated');
    }).catch(e => {
      // console.error(LOG, 'ERROR', 'error', e);
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        attempt: Error(e)
      });
    });
  };
  const getAppSettingsFromSanity = () => {
    const queryString = "*[_type==\"appSettings\"]{\n          newAddBallStepsFlow\n       }";
    console.log('getting App Settings...');
    return theSanityClient.fetch(queryString).then(data => data[0]);
  };
  const createCheckin = checkIn => {
    console.log('About to creat a checkin', checkIn);
    return theSanityClient.create({
      firstname: checkIn.firstname,
      lastname: checkIn.lastname,
      email: checkIn.email,
      houseSubmission: checkIn.houseSubmission,
      business: checkIn.businessName,
      businessUrl: checkIn.businessUrl,
      _type: 'checkin'
    }).then(res => {
      console.log('result from create checkin', res);
      return res;
    });
  };
  const createHouse = house => {
    return fetch("/create-new-house", {
      method: 'POST',
      body: JSON.stringify(house)
    }).then(response => {
      return _clientUtils.default.processResponse(response, 'NewHouseCreated');
    }).catch(e => {
      // console.error(LOG, 'ERROR', 'error', e);
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        attempt: Error(e)
      });
    });
  };
  const createUser = user => {
    console.log('About to create a user', user);
    return theSanityClient.create(_objectSpread(_objectSpread({}, user), {}, {
      _type: 'user'
    })).then(res => {
      console.log('result from create user', res);
      return res;
    });
  };
  const userSubscribe = (sanityId, subscribeFunc) => {
    const query = '*[_type == "user" && _id == $sanityId]';
    const params = {
      sanityId
    };
    return theSanityClient.listen(query, params).subscribe(update => {
      const user = update.result;
      subscribeFunc(user);
      return user;
    });
  };
  const updateAwUser = (user, sanityUserId) => {
    console.log('About to update a user', user, sanityUserId);
    return theSanityClient.patch(sanityUserId).set(_objectSpread({}, user)).commit();
  };
  const updateCheckin = checkinUpdates => {
    console.log('About to update a checkin', checkinUpdates);
    return theSanityClient.patch(checkinUpdates._id).set(_objectSpread({}, checkinUpdates)).commit();
  };
  const addCheckinToCheckinList = (checkinId, checkinListId) => {
    const checkinRef = getSanityDocumentRef(checkinId);
    return theSanityClient.patch(checkinListId).insert('after', 'checkinList[-1]', [_objectSpread(_objectSpread({}, checkinRef), {}, {
      _key: (0, _uuid.v4)()
    })]).commit();
  };
  const fetchAllApprovedBalls = async queryString => {
    console.log('aw - FetchingApprovedBalls with queryObj', queryString);
    // let queryString = const generateBallQueryString(queryStringObj)
    console.log('aw - sanity client in sanityProvider', theSanityClient);
    const response = theSanityClient ? await theSanityClient.fetch("*[_type==\"ball\" && approval == true".concat(queryString, "] | order(_createdAt desc){\n          _id,\n         _createdAt,\n          ...,\n          flyer{\n            asset->{\n              _id,\n              url\n             }\n           },\n       }")) : [];
    console.log("aw - result from fetchAllBalls", response);
    return response;
  };
  const getSanityDocumentRef = sanityId => ({
    _type: 'reference',
    _ref: sanityId
  });
  const uploadImageFromURL = (imageFile, ballId, imageFileUrl) => imageFile.then(imgFileBuffer => {
    console.log('uploading image for', ballId, imgFileBuffer);
    return theSanityClient.assets.upload('image', imgFileBuffer, {
      filename: "".concat(ballId, ".").concat(imageFileUrl.indexOf('png') > 0 ? 'png' : 'jpg')
    }).then(imageAsset => {
      console.log("Ball ".concat(ballId, " image asset created in Sanity"), imageAsset);
      return theSanityClient.patch(ballId).set({
        flyer: {
          _type: 'image',
          asset: {
            _type: 'reference',
            _ref: imageAsset._id
          }
        }
      }).commit();
    }).then(() => {
      console.log('Done adding image to ', ballId);
    });
  });
  const uploadBallFlyerImage = (fileUploaded, ballId) => {
    console.log("Uploading Flyer");
    const data = new FormData();
    // TODO: BLOG ABOUT THIS SHAT B.A.T.S.!!!
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    // for (const key in potentialQuestion) {
    //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //     // @ts-ignore
    //     data.append(key, potentialQuestion[key]);
    // }
    data.append('ballId', ballId);
    data.append('file', fileUploaded);
    data.append('fileName', fileUploaded.name);
    return fetch('/upload-ball-flyer-image', {
      method: 'POST',
      body: data
    }).then(response => {
      return _clientUtils.default.processResponse(response, 'BallFlyerImage');
    }).catch(e => {
      // console.error(LOG, 'ERROR', 'error', e);
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        attempt: Error(e)
      });
    });
  };
  const uploadProfileImage = (imageFile, userId) => {
    console.log('uploading profile image');
    return theSanityClient.assets.upload('image', imageFile, {
      filename: "".concat(userId, ".").concat(_imageUtils.default.extractImageExtensionFromFile(imageFile))
    }).then(imageAsset => {
      console.log('image uploaded updating user', userId, imageAsset);
      return theSanityClient.patch(userId).set({
        profileImage: {
          _type: 'image',
          asset: {
            _type: 'reference',
            _ref: imageAsset._id
          }
        }
      }).commit();
    });
  };
  const addFavorite = (favoriteToAddId, userId) => {
    console.log('CMSCLIENT add ', favoriteToAddId, userId);
    return theSanityClient.patch(userId).setIfMissing({
      favorites: []
    }).insert('after', 'favorites[-1]', favoriteToAddId).commit().then(response => {
      console.log('added favorite results are', response);
      return response;
    });
  };
  const addEventbriteId = (eventbriteId, eventbriteUrl, ballId, dataset) => {
    console.log('CMSCLIENT update eventbrite id ', eventbriteId, ballId, dataset);
    const envSanityClient = theSanityClient;
    return envSanityClient.patch(ballId).setIfMissing({
      eventbriteId,
      eventbriteUrl
    }).commit().then(response => {
      console.log('added eventbrite id', response);
      return response;
    });
  };
  const removeFavorite = (favoriteToRemove, userId) => {
    console.log('CMSCLIENT remove favorite', favoriteToRemove, userId);
    return theSanityClient.patch(userId).unset(favoriteToRemove).commit().then(response => {
      console.log('removed result', response);
      return response;
    });
  };
  const createRefStringFromRefs = listOfFaves => listOfFaves.map(favorite => favorite._ref).join(',');
  const fetchFavorites = listOfBallRefs => {
    if (listOfBallRefs.length === 0) {
      return Promise.resolve([]);
    }
    const normalizedList = (listOfBallRefs === null || listOfBallRefs === void 0 ? void 0 : listOfBallRefs.split) && listOfBallRefs.split(',').join('\',\'');
    const query = "*[_type==\"ball\" && approval == true && _id in ['".concat(normalizedList, "']]{\n          _id,\n          ballTitle,\n          slug,\n          flyer{\n            asset->{\n              _id,\n              url\n             }\n           },\n         ballType,\n         functionStartDate,\n         location,\n         eventbriteId\n       }");
    return theSanityClient.fetch(query).then(data => data);
  };
  const fetchUserBySsoId = ssoUserId => theSanityClient.fetch("*[_type==\"user\" && firebaseUUID == $ssoUserId]{\n          _id,\n          email,\n          slug,\n          profileImage{\n            asset\n          },\n          firebaseUUID,\n          username,\n          signInProvider,\n          favorites[]\n       }[0]", {
    ssoUserId
  }).then(data => data);
  const fetchUserById = sanityUserId => theSanityClient.fetch("*[_type==\"user\" && _id == $sanityUserId]{\n          _id,\n          email,\n          slug,\n          profileImage{\n            asset\n          },\n          firebaseUUID,\n          username,\n          signInProvider,\n          favorites[]\n       }[0]", {
    sanityUserId
  }).then(data => data);
  const subscribeToUserUpdateState = async (ssoId, stateUpdate) => fetchUserBySsoId(ssoId).then(usersObject => {
    if (usersObject && usersObject._id) {
      stateUpdate(usersObject);
      console.log('subscribed to updates from ', usersObject._id);
      return userSubscribe(usersObject._id, user => stateUpdate(user));
    }
    console.log('could not subscribe to user with', ssoId);
    return undefined;
  });
  const getSanityIdFromFirebaseUUID = async firebaseUUID => fetchUserBySsoId(firebaseUUID).then(user => user === null || user === void 0 ? void 0 : user._id);
  const createComment = comment => theSanityClient.create(_objectSpread(_objectSpread({}, comment), {}, {
    _type: 'ballComment'
  })).then(res => res);
  const fetchCommentsByBallId = ballId => theSanityClient.fetch("*[ _type == \"ballComment\" && references($ballId)]| order(_createdAt desc){\n          _createdAt,\n          comment,\n          user->\n        }", {
    ballId
  }).then(data => data);
  const getSanityUserRef = async firebaseUUID => getSanityIdFromFirebaseUUID(firebaseUUID).then(sanityUserId => getSanityDocumentRef(sanityUserId !== null && sanityUserId !== void 0 ? sanityUserId : ""));
  const createContactUs = contactUs => {
    var _createContactUs;
    return fetch((_createContactUs = "/create-contact-us") !== null && _createContactUs !== void 0 ? _createContactUs : "", {
      method: 'POST',
      body: JSON.stringify(contactUs)
    }).then(response => {
      return _clientUtils.default.processResponse(response, 'ContactUsCreated');
    }).catch(e => {
      // console.error(LOG, 'ERROR', 'error', e);
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        attempt: Error(e)
      });
    });
  };
  const addBall = async (ball, flyerImage) => {
    const newBall = await createBall(ball);
    console.log("The new ball as created by sanity", newBall);
    console.log("The new ball id as created by sanity", newBall.response._id);
    if (newBall.response._id) await uploadBallFlyerImage(flyerImage, newBall.response._id);
    return newBall;
  };
  const fetchSkillExperiences = async resumeSkill => {
    return theSanityClient.fetch("*[ _type == \"ResumeExperience\" && references($skillId)]| order(dateStart desc){\n                  ...\n                }", {
      skillId: resumeSkill._id
    }).then(data => {
      console.log("Experiences from groq", data);
      return data;
    });
  };
  const fetchPortfolioItems = async resumeSkill => {
    return theSanityClient.fetch("*[ _type == \"ResumePortfolioItem\" && references($skillId) && isDisabled != true]| order(inceptionDate desc){\n                  ...\n                }", {
      skillId: resumeSkill._id
    }).then(data => {
      console.log("Portfolio items from groq", data);
      return data;
    });
  };
  const newValue = (0, _react.useMemo)(() => ({
    initSanity,
    theSanityClient,
    urlFor,
    cocktailUrlFor,
    skillReferenceSearch,
    fetchRef,
    fetchRefs,
    fetchLandingPage,
    fetchBlogPost,
    fetchLatestBlogPostPreview,
    fetchAllBlogPostPreviews,
    fetchBlogPostPreviewsByCategory,
    fetchBlogPostPreviewsByKeyword,
    fetchLandingPageHeaderMenu,
    fetchLandingPageFooterMenu,
    fetchBlogCategories,
    fetchBlogGroup,
    fetchWhySwitch,
    // useFetchPageBySlugQuery,
    fetchPageBySlugQuery,
    fetchDocumentByTypeAndSlugQuery,
    useFetchMenuBySlugQuery,
    useFetchServicesQuery,
    useFetchRefsQuery,
    useFetchMenuByRefQuery,
    fetchMuiTheme,
    fullTextSearch,
    fetchHomePage,
    fetchOurStoryPage,
    fetchCommunityPage,
    fetchHomePageSpecializationsMenu,
    fetchImageCarousel,
    fetchEvergreenPage,
    getPlaceholderImageUrl,
    placeholderOrImage,
    useFetchAllFlashCards,
    useFetchAllBarIngredients,
    fetchMyBarIngredients,
    useFetchAllLiquorTypes,
    useFetchFilteredIngredients,
    useFetchFilteredCocktails,
    useFetchSearchedCocktails,
    getProduct,
    // useFetchMyBarIngredients,
    useFetchMyFilteredIngredients,
    useFetchMyCocktails,
    getMyProduct,
    getCheckinBySlug,
    fetchBall,
    getBallBySlug,
    createBall,
    getAppSettingsFromSanity,
    createCheckin,
    createHouse,
    createUser,
    updateAwUser,
    updateCheckin,
    addCheckinToCheckinList,
    fetchAllApprovedBalls,
    uploadImageFromURL,
    uploadBallFlyerImage,
    uploadProfileImage,
    addFavorite,
    addEventbriteId,
    removeFavorite,
    createRefStringFromRefs,
    fetchFavorites,
    subscribeToUserUpdateState,
    fetchUserById,
    createComment,
    fetchCommentsByBallId,
    getSanityUserRef,
    createContactUs,
    addBall,
    getSanityDocumentRef,
    fetchSkillExperiences,
    fetchPortfolioItems
  }), [sanityConfig, theSanityClient, urlFor]);
  return (0, _jsxRuntime.jsx)(_SanityContext.default.Provider, {
    value: newValue,
    children: props.children
  });
};
var _default = exports.default = SanityProvider;