"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _material = require("@mui/material");
var _BallType = require("../enums/BallType.enum");
var _BallPage = _interopRequireDefault(require("../ball-page/BallPage"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const PreviewBall = props => (0, _jsxRuntime.jsxs)(_material.Grid, {
  container: true,
  item: true,
  "data-testid": 'add-ball-preview-step',
  direction: 'column',
  spacing: 3,
  children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    children: [(0, _jsxRuntime.jsx)(_material.Typography, {
      variant: 'h5',
      color: 'primary',
      gutterBottom: true,
      children: "Preview your ".concat((0, _BallType.renderBallTypeChoice)(props.newBallToAdd.ballType), " below...")
    }), (0, _jsxRuntime.jsx)(_material.Typography, {
      variant: 'h5',
      color: 'textSecondary',
      children: "When the ".concat((0, _BallType.renderBallTypeChoice)(props.newBallToAdd.ballType), " is approved by Anybody Walking it will appear as shown below.")
    })]
  }), (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    direction: 'column',
    children: (0, _jsxRuntime.jsx)(_BallPage.default, {
      ball: props.newBallToAdd
    })
  })]
});
var _default = exports.default = PreviewBall;