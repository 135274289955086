"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.RoutesEnum = void 0;
var RoutesEnum;
(function (RoutesEnum) {
  RoutesEnum["BALL"] = "/ball";
  RoutesEnum["SHOW_BALL"] = "/ball/:slug";
  RoutesEnum["CONTACT_US"] = "/contactUs";
  RoutesEnum["LANDING"] = "/";
  RoutesEnum["SEARCH"] = "search-balls";
  RoutesEnum["SIGN_UP"] = "/signup";
  RoutesEnum["SIGN_IN"] = "/signin";
  RoutesEnum["HOME"] = "/";
  RoutesEnum["ADD_BALL"] = "/addBall";
  RoutesEnum["ACCOUNT"] = "/account";
  RoutesEnum["ADMIN"] = "/admin";
  RoutesEnum["PASSWORD_FORGET"] = "/passwordForget";
  RoutesEnum["PASSWORD_RESET"] = "/passwordReset";
  RoutesEnum["BALL_INFO"] = "/ballInfo";
  RoutesEnum["NEW_HOUSE"] = "new-house";
  RoutesEnum["EDIT_BALL"] = "/editBall/:ballId";
  RoutesEnum["IMPORT"] = "/import";
  RoutesEnum["FAVORITES"] = "/favorites";
  RoutesEnum["LINK_ACCOUNTS"] = "/linkAccounts";
  RoutesEnum["CHECK_IN"] = "/checkIn/:slug/:step";
  RoutesEnum["CHECK_IN_2"] = "/checkIn2";
  RoutesEnum["CHECK_IN_3"] = "/checkIn3";
})(RoutesEnum || (exports.RoutesEnum = RoutesEnum = {}));
var _default = exports.default = {
  RoutesEnum
};