"use strict";

require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.promise.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _reactRouterDom = require("react-router-dom");
var _Routes = require("./enums/Routes.enum");
var _SanityContext = _interopRequireDefault(require("../../../common/sanityIo/sanity-context/SanityContext"));
var _StyledTextField = _interopRequireDefault(require("./styled-text-field/StyledTextField"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const initialState = {
  newHouseName: '',
  firstName: '',
  lastName: '',
  email: ''
};
const AWNewHouseFormSection = () => {
  const [state, setState] = (0, _react.useState)(initialState);
  const navigate = (0, _reactRouterDom.useNavigate)();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const addHouse = async () => {
    const sanityUserRef = sanityContext.getSanityDocumentRef ? await sanityContext.getSanityDocumentRef("user-id") : undefined;
    let sanityHouse = {
      submittedByEmail: state.email,
      firstname: state.firstName,
      lastname: state.lastName,
      houseName: state.newHouseName
    };
    if (sanityUserRef) {
      sanityHouse = _objectSpread({}, sanityHouse);
    }
    await sanityContext.createHouse(sanityHouse);
    navigate(_Routes.RoutesEnum.HOME);
  };
  const updateNewHouseFormParams = event => {
    console.log(void 0, event);
    event.persist();
    setState(state => _objectSpread(_objectSpread({}, state), {}, {
      [event.target.name]: event.target.value
    }));
  };
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    item: true,
    container: true,
    justifyContent: 'center',
    spacing: 3,
    style: {
      minHeight: "700px",
      paddingTop: "128px",
      paddingLeft: "32px",
      paddingRight: "32px"
    },
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      xs: 12,
      md: 10,
      justifyContent: 'center',
      children: (0, _jsxRuntime.jsx)(_material.Typography, {
        variant: 'h4',
        color: 'textSecondary',
        children: "New House"
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      xs: 8,
      children: (0, _jsxRuntime.jsx)("form", {
        noValidate: true,
        autoComplete: "off",
        children: (0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          spacing: 3,
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            xs: 12,
            children: (0, _jsxRuntime.jsx)(_StyledTextField.default, {
              label: "Official New House Name",
              helperText: "This should be the official name the house would like to be known by in the Ballroom Scene.",
              fullWidth: true,
              onChange: updateNewHouseFormParams,
              name: "newHouseName"
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            xs: 6,
            container: true,
            children: (0, _jsxRuntime.jsx)(_StyledTextField.default, {
              fullWidth: true,
              label: "First Name",
              helperText: "What is your first name?",
              onChange: updateNewHouseFormParams,
              name: "firstName"
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            xs: 6,
            container: true,
            children: (0, _jsxRuntime.jsx)(_StyledTextField.default, {
              fullWidth: true,
              label: "Last Name",
              onChange: updateNewHouseFormParams,
              helperText: "What is your last name?",
              name: "lastName"
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            xs: 12,
            children: (0, _jsxRuntime.jsx)(_StyledTextField.default, {
              label: "E-mail",
              onChange: updateNewHouseFormParams,
              helperText: "Email to be contacted to verify this submission.",
              fullWidth: true,
              name: "email"
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            xs: 12,
            container: true,
            justifyContent: 'center',
            children: (0, _jsxRuntime.jsx)(_material.Button, {
              variant: "contained",
              color: "primary",
              style: {
                width: "180px",
                marginTop: "18px"
              },
              onClick: addHouse,
              children: "Submit"
            })
          })]
        })
      })
    })]
  });
};
var _default = exports.default = AWNewHouseFormSection;